import {
  SwipeableDrawer,
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  Grid,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useGoogleLogout } from "react-google-login";
import React, { useEffect, useMemo, useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import "./AppBar.css";
import Routes, { AppBarLinks } from "routes/RoutesConstants";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import PWaveLogoImg from "assests/image/pWave.png";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { useQueryClient } from "react-query";
import Pomodorail from "components/Pomodorial";
import { FetchUserDetailsAPI } from "containers/LoginPage/LoginQueries";

const ResponsiveAppBar = () => {
  const isLoggedIn = Boolean(JSON.parse(localStorage.getItem("login")));
  const { data: userDetails } = FetchUserDetailsAPI();
  const [openSwipableDrawer, setopenSwipableDrawer] = useState(false);

  const isUser = useMemo(
    () => userDetails?.data?.user?.id ?? "",
    [userDetails?.data?.user?.id]
  );

  const location = useLocation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { signOut: googleSignOut } = useGoogleLogout({
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  });

  const handleOpenNavMenu = (event) => {
    setopenSwipableDrawer(true);
  };
  const handleCloseNavMenu = () => {};

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    openSwipableDrawer((value) => !value);
  };

  const handleLogout = () => {
    queryClient.clear();
    googleSignOut();
    localStorage.clear();

    navigate("/");
  };
  if (location?.pathname.includes("web-view")) return null;
  return (
    <div className="relative">
      <div> {isUser && isLoggedIn && <Pomodorail />}</div>
      <AppBar position="static" className="app-bar " >
        <Container maxWidth="xl" className="app-bar-toolbar-container">
          <Toolbar disableGutters className="shadow-none">
            <Grid
              container
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Grid item>
                <Link to="/">
                  <div className="w-[100px]">
                    <img src={PWaveLogoImg} alt="p-wave logo" />
                  </div>
                </Link>
              </Grid>
              <Grid item>
                <Box
                  sx={{ display: { xs: "none", md: "flex" } }}
                  color="primaryBlack"
                >
                  {!isLoggedIn ? (
                    <>
                      {AppBarLinks.map((navItem) => (
                        <Link
                          to={navItem.link}
                          target={navItem.target}
                          key={navItem.name}
                        >
                          <Typography
                            onClick={handleCloseNavMenu}
                            sx={{ mx: 2, display: "block", fontSize: "16px" }}
                            className="text-appbar-extended-text !text-white !font-bold"
                          >
                            {navItem.name}
                          </Typography>
                        </Link>
                      ))}
                    </>
                  ) : (
                    <>
                      {location.pathname == "/" && (
                        <Grid container alignItems="center">
                          <Grid item>
                            <Link to={"/profile"}>
                              <Typography
                                onClick={handleCloseNavMenu}
                                sx={{
                                  mx: 4,
                                  display: "block",
                                  fontSize: "16px",
                                }}
                                className="text-appbar-extended-text !text-white !font-bold"
                              >
                                Profile
                              </Typography>
                            </Link>
                          </Grid>
                          <Grid item>
                            <PowerSettingsNewIcon
                              sx={{ cursor: "pointer" }}
                              onClick={handleLogout}
                            />
                          </Grid>
                        </Grid>
                      )}
                      {location.pathname === "/p-wave-calendar" && (
                        <Grid container alignItems="center">
                          <Grid item>
                            <Link to={Routes.MINDMAP}>
                              <Typography
                                onClick={handleCloseNavMenu}
                                sx={{
                                  mx: 2,
                                  display: "block",
                                  fontSize: "16px",
                                }}
                                className="text-appbar-extended-text !text-white !font-bold"
                              >
                                Mind Map
                              </Typography>
                            </Link>
                          </Grid>
                        </Grid>
                      )}
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>

            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <SwipeableDrawer
                anchor="right"
                open={openSwipableDrawer}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
              >
                {AppBarLinks.map((navItem) => (
                  <MenuItem
                    key={navItem.name}
                    onClick={handleCloseNavMenu}
                    className="text-appbar-minimized-text"
                  >
                    <Link to={navItem.link} key={navItem.name}>
                      <Typography
                        textAlign="center"
                        className="text-appbar-minimized-text"
                      >
                        {navItem.name}
                      </Typography>
                    </Link>
                  </MenuItem>
                ))}
              </SwipeableDrawer>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};
export default ResponsiveAppBar;
