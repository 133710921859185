import React, { useState } from "react";

import Dialog from "@mui/material/Dialog";

import {
  Radio,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  RadioGroup,
  Typography,
} from "@mui/material";

import moment from "moment";

import "../../MindMap/styles.css";
import { useForm, Controller } from "react-hook-form";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import SingleMonthCalendar from "components/SingleMonthCalendar";
import { Datepicker } from "@mobiscroll/react";
import {
  FetchChartDataAPI,
  HandleAddTaskAPI,
} from "containers/PWaveCalendar/PWaveCalendarQueries";
import { PWaveChart } from "components/PWaveChart";
import { FetchAvailableTimeSlotAPI } from "containers/MindMap/MindMapQueries";

const taskDateValidSchema = yup.object().shape({
  start_time: yup.string().required("Start Time is a required field"),

  end_time: yup.string().required("End Time  is a required field"),
  task_date: yup.date().required("Date  is a required field"),
});

export default function AddTaskDialog(props) {
  const { setAddTaskDialog, openAddTaskDialog, viewData } = props;

  const {
    register,
    handleSubmit,
    watch: dateDetails,
    formState: { errors },
    clearErrors,
    setFocus,
    control,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(taskDateValidSchema),
    defaultValues: {
      start_time: "",
      end_time: "",
      difficulty: "Easy",
      task_date: new Date(),
    },
  });
  const { start_time, end_time, task_date, difficulty } = dateDetails();
  const { data: dayModeData, isSuccess: userPreferenceSuccess } =
    FetchChartDataAPI();

  const { mutate: addTaskDetailsApi } = HandleAddTaskAPI();
  const { data: invalidTimeSlot } = FetchAvailableTimeSlotAPI(
    moment(task_date).format("YYYY-MM-DD")
  );
  const [disabledTime, setDisabledTime] = useState(false);

  const handleClose = () => {
    setAddTaskDialog(null);
  };

  const handleAddTaskWithDate = (data) => {
    let taskData = {
      start_time: data.start_time,
      end_time: data.end_time,
      task_date: moment(data.task_date).format("YYYY-MM-DD"),
      title: viewData.title,
      task_level_id:
        data.difficulty === "Easy" ? 1 : data.difficulty === "Medium" ? 2 : 3,
      is_pomodorial: false,
    };
    addTaskDetailsApi(taskData);
    handleClose();
  };
  const handleAddTaskWithDateError = (data) => {
    console.log(data);
  };
  return (
    <>
      <Dialog
        maxWidth={"sm"}
        open={openAddTaskDialog}
        onClose={handleClose}
        PaperProps={{
          sx: {
            overflow: "visible",
          },
        }}
      >
        <form
          onSubmit={handleSubmit(
            handleAddTaskWithDate,
            handleAddTaskWithDateError
          )}
        >
          <Grid container justifyContent="space-between" className="px-8 py-4">
            <Grid item xs={5}>
              <SingleMonthCalendar
                setMainSelectedDate={(e) => setValue("task_date", e.date)}
                mainSelectedDate={task_date}
              />
            </Grid>
            <Grid item>
              <Divider orientation="vertical" />
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              item
              className="py-2"
              xs={5}
            >
              <Grid item container justifyContent="space-between">
                <Grid item>
                  <Typography variant="h6" className="!text-[14px]">
                    Task Time
                  </Typography>
                </Grid>
                <Grid item container alignItems="center" columns={13}>
                  <Grid item>
                    <Typography variant="subtile2">Start</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Datepicker
                      controls={["time"]}
                      animate="slideup"
                      returnFormat="moment"
                      onChange={(e) => {
                        const time = new Date(e.value);
                        setDisabledTime(moment(time).add(15, "m").toDate());

                        setValue("start_time", e.valueText);
                      }}
                      value={start_time}
                      invalid={(invalidTimeSlot?.data ?? [])?.map((val) => {
                        return {
                          ...val,
                          recurring: {
                            repeat: "daily",
                          },
                        };
                      })}
                      stepMinute={15}
                      touchUi={false}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="subtile2">End</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Datepicker
                      controls={["time"]}
                      stepMinute={15}
                      min={disabledTime}
                      disabled={!Boolean(disabledTime)}
                      onChange={(e) => setValue("end_time", e.valueText)}
                      touchUi={false}
                      value={end_time}
                      invalid={(invalidTimeSlot?.data ?? [])?.map((val) => {
                        return {
                          ...val,
                          recurring: {
                            repeat: "daily",
                          },
                        };
                      })}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid itemx xs={12}>
                <Controller
                  name="difficulty"
                  rules={{ required: "Difficulty level is required" }}
                  control={control}
                  render={({ feild }) => (
                    <RadioGroup
                      row
                      {...feild}
                      aria-labelledby="difficulty-question-1"
                      name="difficulty"
                      value={difficulty}
                      onChange={(e) => setValue("difficulty", e.target.value)}
                    >
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <FormControlLabel
                            value="Easy"
                            control={<Radio />}
                            labelPlacement="start"
                            className="!text-easy !text-left !ml-0"
                            label="Easy"
                          />
                        </Grid>
                        <Grid item>
                          <FormControlLabel
                            value="Medium"
                            control={<Radio />}
                            labelPlacement="start"
                            label="Medium"
                            className="!text-medium text-left !ml-0!ml-0"
                          />
                        </Grid>
                        <Grid item>
                          <FormControlLabel
                            value="Difficult"
                            control={<Radio />}
                            labelPlacement="start"
                            label="Difficult"
                            className="!text-hard text-left !ml-0"
                          />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <PWaveChart
                  preview
                  mindmap
                  height={200}
                  chartData={dayModeData?.data}
                />
              </Grid>
              <Grid
                container
                className="!-mt-2"
                item
                justifyContent="space-between"
              >
                <Grid item xs={4}>
                  <Button
                    fullWidth
                    type="submit"
                    disabled={!start_time || !end_time}
                  >
                    Save
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button fullWidth variant="outlined" onClick={handleClose}>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Dialog>
    </>
  );
}
