import React, { useState } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import AddIcon from "@mui/icons-material/Add";
import AddMonthlyNotesDialog from "./AddMonthlyNotes";
import {
  FetchMonthlyTaskAPI,
  HandleDeleteMonthlyGoalsAPI,
} from "containers/PWaveCalendar/PWaveCalendarQueries";
import moment from "moment";
import NotesImage from "assests/image/pwave-calendar/goals.svg";
import DeleteIcon from "@mui/icons-material/Delete";

const MonthlyPriorityNotes = (props) => {
  const { mainSelectedDate } = props;
  var date = new Date(mainSelectedDate);
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  let month_start_date = moment(firstDay).format("YYYY-MM-DD");
  let month_end_date = moment(lastDay).format("YYYY-MM-DD");
  const [openDialog, setOpenDialog] = useState(false);
  let addIconFlag = true;
  const data = [
    {
      name: "Practice Presentation at 7:00 am",
    },
    {
      name: "Practice Presentation at 7:00 am",
    },
    {
      name: "Practice Presentation at 7:00 am",
    },
    {
      name: "Practice Presentation at 7:00 am",
    },
    {
      name: "Practice Presentation at 7:00 am",
    },
    {
      name: "",
    },
    {
      name: "",
    },
    {
      name: "",
    },
    {
      name: "",
    },
  ];
  const [editData, setEditData] = useState(null);
  const { data: getMonthlyGoals } = FetchMonthlyTaskAPI({
    month_start_date,
    month_end_date,
  });
  const { mutate: deleteMonthlyGoal } = HandleDeleteMonthlyGoalsAPI();
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleEditTask = (data) => {
    setEditData(data);
    handleOpenDialog();
  };
  const handleDeleteGoals = (id) => {
    deleteMonthlyGoal(id);
  };
  return (
    <>
      <Grid
        container
        item
        alignItems="center"
        className="py-2 !bg-aquablue rounded-t-lg"
      >
        <Grid item xs={10}>
          <Typography
            variant="h6"
            className="!font-medium  !text-white"
            align="center"
          >
            Month priorities / notes
          </Typography>
        </Grid>
        <Grid item className="ml-auto">
          <IconButton size="small" onClick={handleOpenDialog}>
            <AddIcon fontSize="small" className="!cursor-pointer !text-white" />
          </IconButton>
        </Grid>
      </Grid>
      <Grid
        container
        item
        className="border-[3px] grow border-aquablue !rounded-b-[13px] "
      >
        {getMonthlyGoals?.data?.length > 0 ? (
          <Grid item xs className=" overflow-y-scroll">
            {getMonthlyGoals?.data?.map((val, i) => {
              let showAddIcon = false;
              if (val.name === "" && addIconFlag) {
                showAddIcon = true;
                addIconFlag = false;
              }
              return (
                <Grid
                  container
                  key={i}
                  alignItems="center"
                  className={`border-b border-[#808080]`}
                >
                  <Grid
                    item
                    className="border-r border-[#808080] py-2 px-4 min-h-[42.5px] min-w-[42px]"
                  >
                    <Typography variant="subtitle2">
                      {val.goal_title && i + 1}
                    </Typography>
                  </Grid>
                  <Grid item xs className="   ">
                    <Typography
                      variant="subtitle2"
                      align="center"
                      className="!font-normal"
                      onClick={() => handleEditTask(val)}
                    >
                      {val.goal_title}
                    </Typography>
                  </Grid>
                  <Grid item className="p-2">
                    {!showAddIcon && (
                      <EditIcon
                        className="!cursor-pointer"
                        fontSize="small"
                        onClick={() => handleEditTask(val)}
                      />
                    )}
                  </Grid>
                  <Grid item className="!mr-2 !cursor-pointer">
                    <DeleteIcon onClick={() => handleDeleteGoals(val.id)} />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
            className="!gap-2 !mt-2"
          >
            <Grid item>
              <img
                src={NotesImage}
                alt="notes"
                className="cursor-pointer"
                onClick={handleOpenDialog}
              />
            </Grid>
            <Grid item>
              <Typography varaint="h6">Add your monthly notes here</Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
      <AddMonthlyNotesDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        editData={editData}
        setEditData={setEditData}
        month_end_date={month_end_date}
        month_start_date={month_start_date}
      />
    </>
  );
};

export default MonthlyPriorityNotes;
