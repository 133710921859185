import { Numbers } from "@mui/icons-material";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAPI, useToast } from "services";

// Sign up

const HandleAddNotes = (body) => {
  const method = "POST";
  const url = `api/notes/create`;
  return useAPI({ method, url, body });
};

export const HandleAddNotesAPI = () => {
  const responseToast = useToast();
  const queryClient = useQueryClient();
  /**
   * Please refer to "https://react-query.tanstack.com/reference/useMutation#_top"
   */
  return useMutation(HandleAddNotes, {
    onSuccess: (response) => {
      responseToast.toastSuccess(
        response.description || "Notes  Added Successfully"
      );
      queryClient.invalidateQueries("GET_NOTES_LIST");
      // ^ redirect the user after sign up page
    },
    onError: (error) => {
      if (error && error.error) {
        responseToast.toastError(
          error.message || "Error while  adding Notes",
          error
        );
      }
    },
  });
};
const HandleDeleteNotes = (params) => {
  const method = "DELETE";
  const url = `api/notes/${params}/delete`;
  return useAPI({ method, url });
};

export const HandleDeleteNotesAPI = () => {
  const responseToast = useToast();
  const queryClient = useQueryClient();
  /**
   * Please refer to "https://react-query.tanstack.com/reference/useMutation#_top"
   */
  return useMutation(HandleDeleteNotes, {
    onSuccess: (response) => {
      responseToast.toastSuccess(
        response.description || "Notes  Deleted Successfully"
      );
      queryClient.invalidateQueries("GET_NOTES_LIST");
      // ^ redirect the user after sign up page
    },
    onError: (error) => {
      if (error && error.error) {
        responseToast.toastError(
          error.message || "Error while  deleting Notes",
          error
        );
      }
    },
  });
};

// Login
const FetchNotes = () => {
  const method = "GET";
  const url = `api/notes`;
  return useAPI({ method, url });
};

export const FetchNotesAPI = () => {
  return useQuery("GET_NOTES_LIST", FetchNotes, {});
};
const FetchSingleMindMap = (body) => {
  console.log(body);
  const { params } = body;
  const method = "GET";
  const url = `api/mindmaps/${params}/show`;
  return useAPI({ method, url });
};

export const FetchSingleMindMapAPI = (payload) => {
  const { params } = payload;

  return useQuery(
    [`GET_SINGLE_MINDMAP`, params],
    () => FetchSingleMindMap(payload),
    {
      enabled: params !== "default",
      onSuccess: () => {
        //
      },
    }
  );
};
