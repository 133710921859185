import React from "react";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import { Eventcalendar, formatDate, getJson } from "@mobiscroll/react";
import "./styles.css";

function WeeklySchedule({ mainSelectedDate, events }) {
  const [myEvents, setEvents] = React.useState([]);
  console.log(events);

  const view = React.useMemo(() => {
    return {
      schedule: {
        type: "week",
        size: 1,
        startDay: 0,
        endDay: 6,
        startTime: "00:00",
        endTime: "24:00",
        timeCellStep: 60,
        timeLabelStep: 0,
        allDay: false,
      },
    };
  }, []);
  const renderDay = (args) => {
    const date = args.date;
    const dayNr = date.getDay();

    return (
      <div className="header-template-container">
        <div className="header-template-date">
          <div className="header-template-day-name">
            <span className="mr-1">{formatDate(" DD", date)}</span>{" "}
            {formatDate("DDD", date)}
          </div>
          <div className="header-template-day"></div>
        </div>
      </div>
    );
  };

  return (
    <Eventcalendar
      theme="ios"
      themeVariant="light"
      clickToCreate={false}
      dragToCreate={false}
      dragToMove={false}
      dragToResize={false}
      actionableEvents={false}
      showControls={false}
      renderDay={renderDay}
      selectedDate={mainSelectedDate}
      cssClass="weekCalendar"
      height={"100%"}
      data={events}
      view={view}
    />
  );
}

export default WeeklySchedule;
