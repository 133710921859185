import { Button, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Login from "assests/image/login.svg";
import "./loginPageStyle.css";
import LoginForm from "./LoginForm";
import PWaveLogoImg from "assests/image/pWave.png";
import { PrimaryButton } from "components/CustomButton";
import Google from "assests/icons/Google.svg";
import FacebookLogin from "assests/icons/Facebooklogin.svg";
import Box from "@mui/material/Box";
import { useLocation, Link } from "react-router-dom";
import RoutesConstants from "routes/RoutesConstants";
import * as yup from "yup";
import { FetchUserDetailsAPI } from "./LoginQueries";
import { useGoogleLogout } from "react-google-login";
import SocialLogin from "./SocialLogin";
import { useQueryClient } from "react-query";
const userValidSignUpSchema = yup.object().shape({
  name: yup
    .string()
    .required("Name is a required field")
    .min(2, "Name length must be min of 2 alphabets")
    .max(26, "Name length must be max of 26 alphabets")
    .matches(/^[aA-zZ\s]+$/, "Please enter a Valid Name"),
  email: yup
    .string()
    .required("Email is a required field")
    .email("Please enter a valid email"),
  password: yup
    .string()
    .required("Password is a required field")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  country: yup.string().required("Country  is a required field"),
  age: yup.string().required("Age  is a required field"),
  gender: yup.string().required("Gender is a required field"),
});
const userValidLoginSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email is a required field")
    .email("Please enter a valid email"),
  password: yup.string().required("Password is a required field"),
});

const LoginPage = () => {
  const location = useLocation();
  const queryClient = useQueryClient();
  const { signOut: googleSignOut } = useGoogleLogout({
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  });

  useEffect(() => {
    queryClient.clear();
    googleSignOut();
  }, []);

  const isLogin = location.pathname === RoutesConstants.LOGIN ? true : false;

  return (
    <div className="bg-container h-full ">
      <Grid
        sx={{ py: { sm: 6 }, px: { sm: 10 }, height: "100%" }}
        container
        className="screen-card"
        justifyContent="center"
        alignItems={"center"}
      >
        <Grid
          item
          xs={12}
          md={12}
          lg={6}
          sx={{ display: { lg: "block", xs: "none" } }}
          align="left"
        >
          <img className="m-auto mt-12" src={Login} alt="login" />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Grid
            container
            //    direction="column"
            justifyContent="center"
            alignItems="center"
            className="login-form-container"
            rowSpacing={2}
            sx={{ margin: "0 auto" }}
          >
            <Grid item sx={{ mb: 1 }}>
              <div className="w-[220px]">
                <img src={PWaveLogoImg} alt="p-wave logo" />{" "}
              </div>
            </Grid>
            <Grid item sx={{ mb: 1 }}>
              <LoginForm
                schema={isLogin ? userValidLoginSchema : userValidSignUpSchema}
              />
            </Grid>

            <Grid item>
              {isLogin ? (
                <Typography
                  variant="subtitle2"
                  color="secondaryBlack.main"
                  align="center"
                >
                  Didn’t have an account?
                  <Link to="/signup">
                    <span className="login-link">Sign Up</span>
                  </Link>
                </Typography>
              ) : (
                <Typography
                  variant="subtitle1"
                  color="secondaryBlack.main"
                  align="center"
                >
                  Already have an account?
                  <Link to="/login">
                    <span className="login-link">Log In</span>
                  </Link>
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ width: "350px" }}>
                <Typography color="secondaryBlack.main">or</Typography>
              </Divider>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                color="secondaryBlack.main"
                align="center"
              >
                {isLogin ? "Login With" : "Sign up with"}
              </Typography>
            </Grid>
            <Grid item sx={{ pb: 3 }}>
              <Grid container columnSpacing={3} alignItems="center">
                <Grid item>
                  <SocialLogin />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default LoginPage;
