import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useAPI, useToast } from "services";
import Routes from "routes/RoutesConstants";
import { BuySubscriptionApi } from "containers/LoginPage/LoginQueries";

const CreatePaymentIntent = () => {
  const method = "POST";
  const url = `api/payment-methods/create-setup-intent`;
  return useAPI({ method, url });
};

export const CreatePaymentIntentAPI = () => {
  const navigate = useNavigate();
  return useMutation(CreatePaymentIntent, {
    enabled: true,
  });
};
const GetCardDetails = () => {
  const method = "GET";
  const url = `api/payment-methods`;
  return useAPI({ method, url });
};

export const GetCardDetailsAPI = () => {
  const navigate = useNavigate();
  return useQuery("GET_CARD_DETAILS", GetCardDetails, {
    enabled: true,
  });
};
const AddCard = (body) => {
  const method = "POST";
  const url = `api/payment-methods`;
  return useAPI({ method, url, body });
};

export const AddCardAPI = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate } = BuySubscriptionApi();
  const responseToast = useToast();
  return useMutation(AddCard, {
    enabled: true,
    onSuccess: () => {
      mutate("", {
        onSuccess: (res) => {
          const mobileDeepLink = process.env.REACT_APP_HOME_DEEP_LINK;
          if (mobileDeepLink) {
            window.location.href = mobileDeepLink;
          }
          queryClient.invalidateQueries("GET_LOGGED_IN_USER_DETAILS");

          // navigate("/");
        },
      });
    },
    onError: (err) => {
      responseToast.toastError(
        err?.error || "Error occured while adding your card! "
      );
    },
  });
};
const ChangeCard = (payload) => {
  const { body, id } = payload;
  const method = "PATCH";
  const url = `api/payment-methods/${id}`;
  return useAPI({ method, url, body });
};

export const ChangeCardAPI = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const responseToast = useToast();
  return useMutation(ChangeCard, {
    enabled: true,
    onSuccess: (res) => {
      console.log(res);
      queryClient.invalidateQueries("GET_LOGGED_IN_USER_DETAILS");
      queryClient.invalidateQueries(["GET_CARD_DETAILS"]);
      responseToast.toastSuccess("Card details Successfully updated");
    },
    onError: (err) => {
      responseToast.toastError(
        err?.error || "Error occured while changing your card! "
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries("GET_LOGGED_IN_USER_DETAILS");
      queryClient.invalidateQueries(["GET_CARD_DETAILS"]);
    },
  });
};
