import React, { useEffect, useState } from "react";
import { Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MindMap from "assests/image/Mind-map.svg";
import Calender from "assests/image/Calender.svg";
import settings from "assests/image/settings.svg";
import videoImage from "assests/image/videoImage.svg";
import EditProfile from "./EditProfile";
import { Link } from "react-router-dom";
import RoutesConstants from "routes/RoutesConstants";
import "./style.css";
import { useLocation } from "react-router-dom";
import PomodorialTaskDialog from "./PomodorialTaskDialog";
import { FetchUserDetailsAPI } from "containers/LoginPage/LoginQueries";
import { useMemo } from "react";
import AddCardDialog from "./AddCardDialog";
import { GetCardDetailsAPI } from "containers/FreeTrail/PaymentQueries";
import NotebookIcon from "assests/image/notes/NotebookIcon.svg";
const ImageContainer = ({
  src,
  alt,
  label,
  onClick,
  link,
  isLoggedIn,
  small,
  is_membership_active,
}) => {
  return (
    <Link to={link && is_membership_active && isLoggedIn ? link : "/"}>
      <Grid container direction="column">
        <Grid
          item
          container
          onClick={isLoggedIn && onClick}
          alignItems="center"
          justifyContent="center"
          className={`${!small && "imageContainer"} ${
            isLoggedIn ? "cursor-pointer" : "cursor-default"
          } `}
        >
          <div className={`${small && "imageContainer"}`}>
            <img
              src={src}
              alt={alt}
              loading="lazy"
              style={{ height: small ? 100 : 160, width: small ? 180 : 280 }}
            />
          </div>
        </Grid>
        <Grid item>
          <Typography
            variant="h5"
            color="secondaryBlack.main"
            sx={{ fontWeight: 600, mt: 3 }}
          >
            {label}
          </Typography>
        </Grid>
      </Grid>
    </Link>
  );
};

const HomePage = (props) => {
  const { state } = useLocation();
  const theme = useTheme();
  const [showSetting, setShowSettings] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const isLoggedIn = Boolean(JSON.parse(localStorage.getItem("login")));
  const [isLoggedInState, setIsLoggedInState] = useState(isLoggedIn);
  // const userDetail = JSON.parse(localStorage.getItem("user_details"));
  const [openTaskDialog, setOpenTaskDialog] = useState(false);
  const [openAddCardDialog, setOpenAddCardDialog] = useState(false);
  const { data: userDetail, dataUpdatedAt } = FetchUserDetailsAPI();
  console.log(userDetail);

  const is_membership_active = useMemo(() => {
    return userDetail?.data?.user?.is_membership_active ?? false;
  }, [dataUpdatedAt]);
  const is_card_addedd = useMemo(() => {
    return userDetail?.data?.user?.is_card_added ?? false;
  }, [dataUpdatedAt]);

  const isSmScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isMdScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const handleShowSettings = () => {
    setShowSettings(!showSetting);
  };
  const handleEditProfileDialog = () => {
    setEditProfile(true);
    setShowSettings(!showSetting);
  };
  const handleOpenCardDialog = () => {
    setOpenAddCardDialog(true);
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  };
  const btnColor = {
    fontSize: 20,
    background: "#998615",
    border: "2px solid #998615",
    "&:hover": {
      fontSize: 20,
      background: "#998615",
      border: "2px solid #998615",
    },
  };
  useEffect(() => {
    setIsLoggedInState(isLoggedIn);
  }, [isLoggedIn]);
  return (
    <Grid container direction="column">
      <Grid item>
        {isLoggedIn ? (
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            className="py-12"
          >
            <Typography
              className="!text-[32px] !font-title !font-medium !italic"
              variant="h5"
            >
              This is your home screen, {userDetail?.data?.user?.name}
            </Typography>
          </Grid>
        ) : (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ backgroundColor: "#E8EDEF", padding: "50px 10px" }}
          >
            <Grid item>
              <Typography
                variant="h5"
                sx={{
                  width: { sm: "80%", md: "75%", lg: "70%" },
                }}
                className="italic !font-bold !text-[30px] !font-title !mx-auto"
                color="primaryBlack.main"
              >
                Your mission, should you choose to accept it, is to rule this
                tool so you can dominate your time, be more productive and say
                good bye to your stress
              </Typography>
            </Grid>

            {isLoggedIn || (
              <Grid item sx={{ mt: 3, pb: 2 }}>
                <Link to={RoutesConstants.SIGNUP}>
                  <Button sx={btnColor}>Start your mission</Button>
                </Link>
              </Grid>
            )}
            <Grid item>
              <Typography variant="h6" color="primaryBlack.main">
                Plan your tasks according to your energy
              </Typography>
              <Typography variant="subtitle2" className="!text-[#998615]">
                p-Wave is the easiest way for an individual to plan, visualize
                and arrange daily tasks in an effective way.
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Grid item className="">
        <Grid
          container
          className="max-w-[1000px] mx-auto mt-16 gap-12 px-8 !relative"
          justifyContent={isMdScreen ? "center" : "space-between"}
          alignItems="center"
        >
          {isLoggedIn && (
            <Link
              className="!absolute right-2 top-[-9.4rem]"
              to={RoutesConstants.NOTES}
            >
              <img src={NotebookIcon} width={30} />
            </Link>
          )}
          <Grid item>
            <ImageContainer
              is_membership_active={is_membership_active}
              src={MindMap}
              onClick={
                is_membership_active ? scrollToTop : handleOpenCardDialog
              }
              isLoggedIn={isLoggedIn}
              alt="mind-map"
              label="To Do Mind Map"
              link={RoutesConstants.MINDMAP}
            />
          </Grid>
          <Grid item>
            <ImageContainer
              is_membership_active={is_membership_active}
              src={Calender}
              isLoggedIn={isLoggedIn}
              onClick={
                is_membership_active ? scrollToTop : handleOpenCardDialog
              }
              alt="claender"
              label="p-Wave Calendar"
              link={RoutesConstants.PWAVECALENDAR}
            />
          </Grid>
        </Grid>
        <Grid
          container
          className="max-w-[1000px] mx-auto my-16 mb-20  gap-12 px-8"
          justifyContent={isMdScreen ? "center" : "space-between"}
          alignItems="center"
        >
          <Grid item xs={4} className="!relative">
            {showSetting && (
              <>
                <Link to={RoutesConstants.PROFILE}>
                  <Button
                    onClick={scrollToTop}
                    className="!absolute -left-40 top-20 w-32 !text-lg animate-fadeIn"
                  >
                    General
                  </Button>
                </Link>
                <Link
                  to={
                    is_membership_active ? RoutesConstants.CALENDAR_SETUP : "/"
                  }
                  onClick={
                    is_membership_active ? scrollToTop : handleOpenCardDialog
                  }
                >
                  <Button className="!absolute -right-40 top-20 w-32 !text-lg  animate-fadeIn">
                    Calendar
                  </Button>
                </Link>
                <Button
                  onClick={() =>
                    is_membership_active
                      ? setOpenTaskDialog(true)
                      : handleOpenCardDialog()
                  }
                  className="!absolute -bottom-16 right-[70px]  !text-md  animate-fadeIn"
                >
                  Pomodoro technique
                </Button>
              </>
            )}
            <ImageContainer
              small
              is_membership_active={is_membership_active}
              src={settings}
              isLoggedIn={isLoggedIn}
              onClick={handleShowSettings}
              alt="mind-map"
              label="Settings"
            />
          </Grid>
          <Grid item xs={4}>
            <ImageContainer
              small
              is_membership_active={is_membership_active}
              src={videoImage}
              isLoggedIn={isLoggedIn}
              link={RoutesConstants.VIDEO}
              onClick={
                is_membership_active ? scrollToTop : handleOpenCardDialog
              }
              alt="mind-map"
              label="Videos"
            />
          </Grid>
        </Grid>
      </Grid>
      {isLoggedIn || (
        <Grid item sx={{ zIndex: 100 }}>
          <Grid
            container
            justifyContent={isMdScreen ? "center" : "space-between"}
            direction={isMdScreen ? "column" : "row"}
            alignItems="center"
            className="cta"
            sx={{ gap: { md: 0, xs: 3 } }}
          >
            <Grid item>
              <Grid
                container
                direction="column"
                alignItems={isMdScreen ? "Center" : "flex-start"}
                rowSpacing={1}
              >
                <Grid item>
                  <Typography
                    variant="h4"
                    color={theme.palette.primaryBlack.main}
                  >
                    Ready to get started?
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h4" color={"#808080"}>
                    Click on the button & Enjoy
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Link to={RoutesConstants.SIGNUP}>
                <Button sx={btnColor}>Get Started</Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      )}
      <PomodorialTaskDialog
        openDialog={openTaskDialog}
        userDetails={userDetail?.data.user}
        setOpenDialog={setOpenTaskDialog}
      />
      <AddCardDialog
        openDialog={openAddCardDialog}
        setOpenDialog={setOpenAddCardDialog}
        is_card_addedd={is_card_addedd}
        is_membership_active={is_membership_active}
      />
    </Grid>
  );
};

export default HomePage;
