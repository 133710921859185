import {
  Button,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Spinner from "assests/image/notes/calendar.svg";
import Calendar from "assests/image/notes/spinner.svg";
import Delete from "assests/image/notes/delete.svg";
import noNotes from "assests/image/notes/noNotes.svg";

import {
  FetchNotesAPI,
  HandleAddNotesAPI,
  HandleDeleteNotesAPI,
} from "./NotesQueries";
import AddNotesDialog from "./Components/AddNotes";
import AddTaskDialog from "./Components/AddTaskDialog";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RoutesConstants from "routes/RoutesConstants";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
const tempData = [
  { title: "Have to buy a gift for a friend", time: "7 day ago" },
  { title: "Have to buy a gift for a friend", time: "7 day ago" },
  { title: "Have to buy a gift for a friend", time: "7 day ago" },
  { title: "Have to buy a gift for a friend", time: "7 day ago" },
  { title: "Have to buy a gift for a friend", time: "7 day ago" },
  { title: "Have to buy a gift for a friend", time: "7 day ago" },
  { title: "Have to buy a gift for a friend", time: "7 day ago" },
  { title: "Have to buy a gift for a friend", time: "7 day ago" },
  { title: "Have to buy a gift for a friend", time: "7 day ago" },
  { title: "Have to buy a gift for a friend", time: "7 day ago" },
  { title: "Have to buy a gift for a friend", time: "7 day ago" },
];
const NoteBook = () => {
  const navigate = useNavigate();
  const { data: NotesList } = FetchNotesAPI();
  const { mutate: delteNote } = HandleDeleteNotesAPI();
  const [viewData, setViewData] = useState(null);
  const [openAddTaskDialog, setAddTaskDialog] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);

  const deleteNote = (id) => {
    delteNote(id);
  };
  const handleViewData = (data) => {
    setViewData(data);
    setOpenDialog(true);
  };

  return (
    <div className="max-width gap-10 !my-12 App-Container">
      <Grid container justifyContent="center">
        <Grid
          container
          item
          justifyContent="space-between"
          alignItems="center"
          className="mb-16"
          columns={14}
        >
          <Grid item xs={2}>
            <Button
              onClick={() => navigate(-1)}
              size="large"
              variant="outlined"
              fullWidth
            >
              Back
            </Button>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              rowSpacing={1}
            >
              <Grid item>
                <Typography
                  className="!text-[25px] !font-title !font-medium !italic"
                  variant="h5"
                >
                  Notes
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Link to={RoutesConstants.MINDMAP}>
              <Button fullWidth size="large" variant="outlined">
                Mind Map
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Grid>
      {NotesList?.data?.length > 0 ? (
        <Grid container direction="column" className="gap-6 px-12 mt-12">
          <Grid container item justifyContent="flex-end">
            <Button
              className="!bg-textbalck !font-light"
              sx={{ padding: "4px 20px", fontSize: "18px" }}
              onClick={() => setOpenDialog(true)}
            >
              Add Notes
            </Button>
          </Grid>
          <Grid
            item
            container
            direction="column"
            className="!shadow-[0px_1px_10px_rgba(0,0,0,0.1)] px-5 py-4 rounded-lg gap-3"
          >
            {NotesList?.data.map((val) => (
              <>
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={5}
                    container
                    direction="column"
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <Typography
                        align="left"
                        variant="h6"
                        color="secondaryBlack.main"
                        className="!text-[16px]  !font-medium "
                      >
                        {val?.title}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        className="!text-[12px]   "
                        align="left"
                        color="gray.main"
                        variant="body2"
                      >
                        7 hours ago
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs="auto" container className="gap-3">
                    <Grid item>
                      <Tooltip title="Delete">
                        <IconButton
                          size="small"
                          onClick={() => deleteNote(val.id)}
                        >
                          <img src={Delete} width={15} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Tooltip title="Add To Calendar">
                        <IconButton
                          size="small"
                          onClick={() => {
                            setViewData(val);
                            setAddTaskDialog(true);
                          }}
                        >
                          <img src={Calendar} width={25} />
                        </IconButton>
                      </Tooltip>
                    </Grid>

                    <Grid item>
                      <Tooltip title="Add to MindMap">
                        <IconButton
                          size="small"
                          onClick={() =>
                            navigate(`${RoutesConstants.MINDMAP}`)
                          }
                        >
                          <img src={Spinner} width={20} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Tooltip title="View">
                        <IconButton
                          size="small"
                          onClick={() => handleViewData(val)}
                        >
                          <VisibilityIcon color="primary" width={25} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ width: "100%" }} fullWidth />
                </Grid>
              </>
            ))}
          </Grid>
        </Grid>
      ) : (
        <div className="flex flex-col justify-center items-center mt-[5%] gap-4">
          <img src={noNotes} alt=" no notes" />
          <p className="text-[20px]  text-textbalck opacity-70 ">
            Add your notes here.
          </p>
          <Button
            className="!bg-textbalck !font-light"
            sx={{ padding: "4px 20px", fontSize: "18px" }}
            onClick={() => setOpenDialog(true)}
          >
            Add Notes
          </Button>
        </div>
      )}
      <AddNotesDialog
        setOpenDialog={setOpenDialog}
        openDialog={openDialog}
        viewData={viewData}
        setViewData={setViewData}
      />
      <AddTaskDialog
        setAddTaskDialog={setAddTaskDialog}
        openAddTaskDialog={openAddTaskDialog}
        viewData={viewData}
      />
    </div>
  );
};

export default NoteBook;
