import React, { useEffect } from "react";

import Dialog from "@mui/material/Dialog";

import { Button, Grid, Typography } from "@mui/material";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Routes from "routes/RoutesConstants";
import { Link } from "react-router-dom";
import {
  CreatePaymentIntentAPI,
  GetCardDetailsAPI,
} from "containers/FreeTrail/PaymentQueries";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "containers/FreeTrail/PaymentForm";

export default function ChangeCardDialog(props) {
  const { openDialog, setOpenDialog } = props;

  const { data: cardDetails, refetch: refetchCardDetails } =
    GetCardDetailsAPI();
  console.log(cardDetails);

  const {
    mutate: getStipePaymentItnent,
    data: paymentIntentDetails,
    isLoading,
  } = CreatePaymentIntentAPI();

  const stripePromise = loadStripe(
    `${paymentIntentDetails?.data?.publishable_key}`
  );
  console.log(paymentIntentDetails);
  useEffect(() => {
    getStipePaymentItnent();
  }, []);

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      {isLoading || (
        <Dialog
          maxWidth={"xs"}
          open={openDialog}
          onClose={handleClose}
          PaperProps={{
            sx: {
              overflow: "visible",
              padding: "20px 10px",
            },
          }}
        >
          <div className="flex justify-center">
            <Elements stripe={stripePromise}>
              <PaymentForm
                paymentIntentDetails={paymentIntentDetails}
                paymentDetailId={cardDetails?.data?.[0]?.id}
                handleClose={handleClose}
                refetchCardDetails={refetchCardDetails}
              />
            </Elements>
          </div>
        </Dialog>
      )}
    </>
  );
}
