import React from "react";
import PaymentForm from "./PaymentForm";
import PaymentPageImage from "assests/image/payment/PaymentPage.svg";
import CardType from "assests/image/payment/CardType.svg";
import SecureLock from "assests/image/payment/SecureLock.svg";
import { Grid, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const TrailForm = () => {
  return (
    <div className="border max-w-[500px] py-14 rounded-lg	border-grayborder">
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item>
          <Typography
            color="secondaryBlack.main"
            className="!text-[16px]"
            variant="subtitle"
          >
            Set up your payment
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            color="secondaryBlack.main"
            className="!my-4 !font-medium !opacity-70"
            variant="h5"
          >
            Start your 15 days free trial.
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            color="secondaryBlack.main"
            className="!mb-2"
            variant="subtitle2"
          >
            No commitments.
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            color="secondaryBlack.main"
            className="!mb-2"
            variant="subtitle2"
          >
            Cancel online anytime.
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            className="!font-normal"
            color="secondaryBlack.main px-4 leading-1"
            variant="subtitle2"
          >
            Pwave will automatically continue your membership and charge the
            membership fee (currently $ 8.50/month) to your payment method until
            you cancel. You may cancel at any time to avoid future charges.
          </Typography>
        </Grid>
        <Grid
          container
          item
          justifyContent="flex-end"
          alignItems="center"
          columnSpacing={1}
          className="!mt-10 !m-1 !w-11/12 !mr-4"
        >
          <Grid item>
            <Typography
              color="secondaryBlack.main"
              className="!opacity-70 !text-[12px]"
              variant="body2"
            >
              Secure Server
            </Typography>
          </Grid>
          <Grid item>
            <img src={SecureLock} width={10} alt="secure lock" />
          </Grid>
        </Grid>
        <Grid
          container
          item
          alignItems="center"
          className=" border px-1 py-2 !w-11/12 !gap-4 rounded-lg	!cursor-pointer  "
        >
          <Grid item>
            <Typography
              color="secondaryBlack.main"
              className="!opacity-70"
              variant="subtitle2"
            >
              Credit or Debit Card
            </Typography>
          </Grid>
          <Grid item>
            <img className="!w-[150px]" src={CardType} alt="cardType" />
          </Grid>
          <Grid item className="!ml-auto">
            <ChevronRightIcon className="!text-textbalck !opacity-70" />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default TrailForm;
