import axios from "axios";
import ROUTES from "routes/RoutesConstants";
import { useGoogleLogout } from "react-google-login";
import moment from "moment-timezone";

/**
 * API wrapper
 * will handle all configurations and defaults for the api calls
 * Please use this wrapper for all the api calls
 */

const Api = ({
  method,
  url,
  body,
  contentType = "application/json",
  params,
}) => {
  return new Promise((resolve, reject) => {
    const Authorization = `Bearer ${localStorage.getItem("token")}`;
    console.log(moment?.tz?.guess());

    const headers = {
      "content-type": contentType,
    };
    if (Boolean(localStorage.getItem("token"))) {
      headers.Authorization = Authorization;
      headers["x-device-timezone"] = moment?.tz?.guess();
    }

    const payload = {
      method,
      url: url.includes("http")
        ? url
        : `${process.env.REACT_APP_API_URL}${url}`,
      headers,
      params,
    };

    // including 'data' if body is not null
    if (body != null) {
      payload.data = body;
    }

    axios(payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(JSON.parse(JSON.stringify(error)));
        reject(error?.response?.data);
        console.log(error);
        if (JSON.parse(JSON.stringify(error))?.status === 401) {
          sessionStorage.clear();
          localStorage.clear();

          //      window.location = ROUTES.LOGIN;
        }
      });
  });
};
export default Api;
