import React, { useEffect } from "react";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import {
  CalendarNav,
  CalendarNext,
  CalendarPrev,
  Datepicker,
  Eventcalendar,
  getJson,
} from "@mobiscroll/react";
import "./styles.css";
import moment from "moment";

function SingleMonthCalendar({
  mainSelectedDate,
  setMainSelectedDate,
  events,
}) {
  const date = new Date(); // 2009-11-10
  const defaultMonth = date.toLocaleString("default", { month: "long" });
  const [myEvents, setEvents] = React.useState([]);
  const [month, setMonth] = React.useState(defaultMonth);
  console.log(month, "month");

  const handleMonthChange = (event, inst) => {
    const date = event.month; // 2009-11-10
    const localMonth = date.toLocaleString("default", { month: "long" });

    setMonth(localMonth);
  };
  console.log(events, mainSelectedDate, "events");
  const customWithNavButtons = () => {
    return (
      <div className="-2 flex justify-between items-center w-full">
        <p className="my-custom-title !text-[#13b3e5] !font-semibold !text-[18px] !ml-2">
          {month}
        </p>
        <div>
          <CalendarPrev />
          <CalendarNext />
        </div>
      </div>
    );
  };
  const onDateClick = (event, inst) => {
    // setSelectedDate(
    //   inst.getVal().map((item) => moment(item).format("YYYY-MM-DD"))
    // );
  };
  const handleDateChange = (event, date) => {
    onDateClick && onDateClick(event);
    setMainSelectedDate({ date: event.date, type: "Day" });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const responsive = React.useMemo(() => {
    return {
      custom: {
        // Custom breakpoint
        breakpoint: 300,
        view: {
          calendar: {
            labels: false,
          },
        },
      },
    };
  }, []);
  useEffect(() => {
    if (mainSelectedDate) {
      const localMonth = mainSelectedDate.toLocaleString("default", {
        month: "long",
      });

      setMonth(localMonth);
    }
  }, [mainSelectedDate]);

  return (
    <div className="dailogCalendar px-4">
      <Datepicker
        controls={["calendar"]}
        display="inline"
        touchUi={true}
        clickToCreate={false}
        dragToCreate={false}
        dragToMove={false}
        showOuterDays={false}
        dragToResize={false}
        actionableEvents={false}
        value={mainSelectedDate}
        onCellClick={(event, inst) => handleDateChange(event, inst)}
        // onEventClick={onEventClick}
        cssClass="dailogCalendar"
        exclusiveEndDates={true}
        renderCalendarHeader={customWithNavButtons}
        dayNamesMin={["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]}
        onPageChange={handleMonthChange}
        colors={events}
        view={{
          calendar: { type: "month", size: 1, outerDays: false },
        }}
      />
    </div>
  );
}

export default SingleMonthCalendar;
