import { yupResolver } from "@hookform/resolvers/yup";

import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/system";
import FogotPassword from "./ForgotPasswordDialog";
import LabelledTextField from "components/LabelledTextField";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import RoutesConstants from "routes/RoutesConstants";
import LabelledTextFieldWithAdornments from "components/LabelledTextFieldWithAdornments";
import { HandleLoginFormAPI, HandleSignUpAPI } from "./LoginQueries";
import ForgotPasswordEmail from "./ForgotPasswordDialog";
import ForgotPassword from "./ResetPassowrd";
import IntroVideo from "./IntroVideo";
// import Styles from "./LoginPage.module.scss";

const verifyEmailSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email is a required field")
    .email("Please enter a valid email"),
});
const newPasswordSchema = yup.object().shape({
  password: yup.string().required("Password is a required field"),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const LoginForm = (props) => {
  const { schema } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const isLogin = location.pathname === RoutesConstants.LOGIN ? true : false;

  const {
    register,
    handleSubmit,
    watch: loginFormCredentials,
    formState: { errors },
    clearErrors,
    setFocus,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      gender: "male",
    },
  });

  const [openModal, setOpenModal] = React.useState(false);
  const [resetPassword, setResetPassword] = React.useState(false);
  const [openResetModal, setOpenResetModal] = React.useState(false);
  const forgotPassToken = location.search.split("=")[1];
  const { gender } = loginFormCredentials();
  const [IntroVideoModal, setIntroVideoModal] = useState(false);

  useEffect(() => {
    if (Boolean(forgotPassToken)) {
      setOpenResetModal(true);
    }
  }, [forgotPassToken]);

  // useEffect(() => {
  //   setFocus("name");
  // }, [setFocus]);
  useEffect(() => {
    clearErrors();
    reset();
    return () => reset();
  }, [isLogin]);

  const { mutate: ValidationLoginCredentials } = HandleLoginFormAPI();

  const { mutate: SignUpUser } = HandleSignUpAPI();

  const submitLoginForm = (data) => {
    data.is_social = false;
    data.is_admin = false;

    // navigate("/", { state: true });
    //  ValidationLoginCredentials(data);
    if (isLogin) {
      // login API
      ValidationLoginCredentials(data);
    } else {
      // sign up API

      SignUpUser(data, {
        onSuccess: (res) => {
          setIntroVideoModal(true);
          console.log(res, "hello, You are signup");
        },
      });
    }
  };
  const handleLoginError = (data) => {
    //  ValidationLoginCredentials(data);
  };
  const openForgetPsswordModal = () => {
    clearErrors();
    reset();
    setOpenModal(true);
  };
  const { ref: refRegisterName, ...RegisterLoginName } = register("name");
  const { ref: refRegisterCountry, ...RegisterLoginCountry } =
    register("country");
  const { ref: refRegisterAge, ...RegisterLoginAge } = register("age");
  const { ref: refRegisterEmail, ...RegisterLoginEmail } = register("email");

  const { ref: refRegisterPassword, ...RegisterLoginPassword } =
    register("password");

  return (
    <>
      <IntroVideo IntroVideoModal={IntroVideoModal} />
      <form
        onSubmit={handleSubmit(submitLoginForm, handleLoginError)}
        id="login-form"
      >
        <Grid container rowSpacing={2}>
          {!isLogin && (
            <Grid item xs={12}>
              <LabelledTextField
                name="name"
                label="Name"
                type="text"
                inputRef={refRegisterName}
                {...RegisterLoginName}
                error={Boolean(errors.name)}
                helperText={errors && errors.name && errors.name.message}
                autoComplete="text"
                onChange={(e) =>
                  setValue(
                    "name",
                    e.target.value
                      .split(" ")
                      .map(
                        (text) =>
                          text[0].toUpperCase() + text.slice(1, text.index)
                      )
                      .join(" ")
                  )
                }
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <LabelledTextField
              name="email"
              label="Email Id"
              type="email"
              inputRef={refRegisterEmail}
              {...RegisterLoginEmail}
              error={Boolean(errors.email)}
              helperText={errors && errors.email && errors.email.message}
              autoComplete="email"
            />
          </Grid>
          <Grid item xs={12}>
            <LabelledTextFieldWithAdornments
              name="password"
              label="Password"
              type="password"
              inputRef={refRegisterPassword}
              {...RegisterLoginPassword}
              error={Boolean(errors.password)}
              helperText={errors && errors.password && errors.password.message}
              autoComplete="password"
            />
          </Grid>
          {!isLogin && (
            <Grid item container rowSpacing={2}>
              <Grid item xs={12}>
                <LabelledTextField
                  name="country"
                  label="Country"
                  type="text"
                  inputRef={refRegisterCountry}
                  {...RegisterLoginCountry}
                  error={Boolean(errors.country)}
                  helperText={
                    errors && errors.country && errors.country.message
                  }
                  autoComplete="text"
                />
              </Grid>
              <Grid item xs={12}>
                <LabelledTextField
                  name="age"
                  label="Age"
                  type="number"
                  inputRef={refRegisterAge}
                  {...RegisterLoginAge}
                  error={Boolean(errors.age)}
                  helperText={errors && errors.age && errors.age.message}
                  autoComplete="text"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl sx={{ width: "100%" }}>
                  <RadioGroup
                    row
                    aria-labelledby="energy-question-1"
                    name="energy-question-1"
                    value={gender}
                    defaultValue="male"
                    onChange={(e) => setValue("gender", e.target.value)}
                  >
                    <Grid container alignItems="center">
                      <Grid item>
                        <FormControlLabel
                          value="male"
                          control={<Radio />}
                          labelPlacement="start"
                          className="!text-Male !text-left !ml-0"
                          label="Male"
                        />
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          value="female"
                          control={<Radio />}
                          labelPlacement="start"
                          label="Female"
                          sx={{ color: "#000" }}
                          className=" text-left !ml-0!ml-0"
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          )}

          {isLogin && (
            <Grid xs={12}>
              <Typography
                variant="subtitle2"
                color="primaryBlack.main"
                align="right"
                className="cursor-pointer"
                onClick={openForgetPsswordModal}
              >
                Forgot Password?
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} sx={{ width: "98%" }}>
            <Button
              fullWidth={true}
              id="login-form"
              type="submit"
              sx={{ fontSize: 18 }}
            >
              {isLogin ? "Log In" : "Sign Up"}
            </Button>
          </Grid>
        </Grid>
      </form>
      <ForgotPasswordEmail
        setOpenModal={setOpenModal}
        openModal={openModal}
        setResetPassword={setResetPassword}
        resetPassword={resetPassword}
        schema={resetPassword ? newPasswordSchema : verifyEmailSchema}
      ></ForgotPasswordEmail>
      <ForgotPassword
        setOpenResetModal={setOpenResetModal}
        openResetModal={openResetModal}
        schema={newPasswordSchema}
        token={forgotPassToken}
      />
    </>
  );
};

export default LoginForm;
