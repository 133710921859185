import { Navigate, useLocation, useNavigate } from "react-router-dom";
import ProfilePage from "./EditProfile";
import { FetchUserDetailsAPI, HandleRefreshAccesTokenAPI } from "containers/LoginPage/LoginQueries";
import { useEffect, useState } from "react";
import FreeTrailPage from "containers/FreeTrail";


export const UserProfile = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const urlQuery = location.search;
    const refresh_token = urlQuery.split('=')[1];
    const { mutate: refreshToken } = HandleRefreshAccesTokenAPI();
    const { data: userDetails } = FetchUserDetailsAPI();
    const [isAccessToken, setAccessToken] = useState(localStorage.getItem('token'));
    const [isLoading, setLoading] = useState(true);

    const handleRefreshTokenApi = (refresh_token) => {
        refreshToken({ refresh_token }, {
            onSuccess: (res) => {
                setAccessToken(localStorage.getItem('token'));
                
            },
            onError: (err) => {
                localStorage.removeItem('token');
                navigate('/login');
                console.log("Err:", err)
            }
        })
    }

    useEffect(() => {
        if (refresh_token) {
            handleRefreshTokenApi(refresh_token);
        }
    }, [refresh_token]);
    useEffect(() => {
        if (userDetails) {
            setLoading(false);
        }
    }, [userDetails]);

    return (
        <>
            {
                userDetails && userDetails.data && userDetails.data.user && userDetails.data.user.is_card_added ?
                    <ProfilePage /> :
                    <FreeTrailPage />
            }
        </>

    )
}