import { createTheme, responsiveFontSizes } from "@mui/material/styles";

/**
 * Theme
 */
const commonTheme = createTheme({
  palette: {
    primaryBlack: {
      main: "#102040",
      contrastText: "#FFFFFF",
    },
    action: {
      disabled: "#fff",
    },
    secondaryBlack: {
      main: "#0A0A0A",
    },
    appBar: {
      // extended: "#121254",
      // minimized: "#121254",
    },
    inputLabel: {
      main: "#808080",
    },
    appBarButton: {
      main: "#FFFFFF",
      contrastText: "#FFFFFF",
    },
    grayText: {
      main: "#545454",
    },
    gray: {
      main: "#808080",
    },

    // secondary: {
    //   main: "#264265B3",
    // },
    // text: {
    //   main: "#264265",
    // },

    // primaryBlue: {
    //   main: "#3699FF",
    //   contrastText: "#fff",
    // },
    // secondaryBlue: {
    //   main: "#789DFE",
    //   contrastText: "#fff",
    // },
    // blue: {
    //   main: "#56CCF2",
    //   contrastText: "#fff",
    // },
    // toggleTabs: {
    //   main: "#234266",
    //   contrastText: "#fff",
    // },
    // orange: {
    //   main: "#ff6c44",
    //   contrastText: "#fff",
    // },
    // componentTitle: {
    //   main: "#000000CC",
    // },
    // listSecondary: {
    //   main: "#597599",
    // },
  },
  typography: {
    fontFamily: `"Poppins", sans-serif -webkit-font-smoothing: antialiased -moz-osx-font-smoothing: grayscale !important`,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: ".5rem 1.5rem",
          background: "#102040",
          borderRadius: "8.0614px",
          textTransform: "capitalize",
          border: "2px solid #102040",
          color: "#fff",
          "&:hover": {
            background: "#102040",
            border: "2px solid #102040",
            color: "#fff",
          },
        },
        outlined: {
          padding: ".5rem 1.5rem",
          background: "#fff",
          borderRadius: "8.0614px",

          textTransform: "capitalize",
          color: "#102040",
          border: "2px solid #102040",
          "&:hover": {
            background: "#fff",
            border: "2px solid #102040",
            color: "#102040",
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: "70px !important",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&::before": {
            borderBottom: "3px solid #808080",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 16,
          fontWeight: 500,
          color: "#0A0A0A",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "inherit",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paperWidthXs: {
          padding: "25px",
          minWidth: "500px",
          borderRadius: 8,
        },
        paperWidthSm: {
          padding: "0px",
          minWidth: "780px",
          borderRadius: 8,
        },
      },
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        p: {
          marginBlockStart: 0,
          marginBlockEnd: 0,
          marginInlineStart: 0,
          marginInlineEnd: 0,
        },
        "*": {
          "scrollbar-width": "thin",
        },
        "*::-webkit-scrollbar": {
          width: "0.5em",
          height: "0.5em",
        },
        "*::-webkit-scrollbar-track": {
          boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          margin: 40,
        },
        "*::-webkit-scrollbar-thumb": {
          backgroundColor: "rgba(0,0,0,.1)",
          borderRadius: "6px",
        },
        "*::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "rgba(0,0,0,.4)",
          borderRadius: "6px",
        },
      },
    },
  },
});

// Do your common overrides here
/**
 * Typography - body1
 */
// commonTheme.typography.body1.fontSize = "0.8rem";
// commonTheme.typography.body1.color = commonTheme.palette.text.primary;

/**
 * Typography - caption
 */
commonTheme.typography.caption.color = commonTheme.palette.text.hint;
/**
 *  ** THIS SHOULD BE LAST **
 * Root Theme
 */
const rootTheme = responsiveFontSizes(commonTheme);

export default rootTheme;
