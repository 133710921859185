import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, Typography } from "@mui/material";
import LabelledTextField from "components/LabelledTextField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import LabelledTextFieldWithAdornments from "components/LabelledTextFieldWithAdornments";
import { HandleChangePasswordAPI } from "./EditProfileQueries";

export default function ResetPassword(props) {
  const {
    openModal,
    setOpenModal,
    children,
    isCurrentPasswordRequired,

    schema,
  } = props;
  const {
    register,
    handleSubmit,
    watch: loginFormCredentials,
    formState: { errors },
    clearErrors,
    reset,
    setFocus,
  } = useForm({ resolver: yupResolver(schema) });
  const { mutate: changePasswordAPI } = HandleChangePasswordAPI();
  const { ref: refRegisterEmail, ...RegisterLoginEmail } =
    register("current_password");
  const { ref: refRegisterPassword, ...RegisterLoginPassword } =
    register("new_password");
  const {
    ref: refRegisterPasswordConfirmation,
    ...RegisterLoginPasswordConfirmation
  } = register("confirm_password");

  const handleClose = () => {
    clearErrors();
    reset();
    setOpenModal(false);
  };

  const handleResetPassword = (data) => {
    let payload = {};
    payload = {
      is_current_password_required: isCurrentPasswordRequired,
      ...data,
    };
    changePasswordAPI(payload);
    handleClose();
  };

  const handleResetPasswordError = (data) => {
    console.log();
  };

  return (
    <div className="px-12">
      <Dialog
        fullWidth={true}
        maxWidth={"xs"}
        open={openModal}
        onClose={handleClose}
      >
        <form
          onSubmit={handleSubmit(handleResetPassword, handleResetPasswordError)}
        >
          <DialogContent>
            <Grid container rowSpacing={2}>
              {isCurrentPasswordRequired && (
                <Grid item xs={12}>
                  <LabelledTextField
                    label="Old Password"
                    inputRef={refRegisterEmail}
                    {...RegisterLoginEmail}
                    error={Boolean(errors.old_password)}
                    helperText={
                      errors &&
                      errors.old_password &&
                      errors.old_password.message
                    }
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <LabelledTextFieldWithAdornments
                  label="Password"
                  type="password"
                  inputRef={refRegisterPassword}
                  {...RegisterLoginPassword}
                  error={Boolean(errors.password)}
                  helperText={
                    errors && errors.new_password && errors.new_password.message
                  }
                  autoComplete="password"
                />
              </Grid>
              <Grid item xs={12}>
                <LabelledTextFieldWithAdornments
                  label="Confirm Password"
                  type="password"
                  inputRef={refRegisterPasswordConfirmation}
                  {...RegisterLoginPasswordConfirmation}
                  error={Boolean(errors.confirm_password)}
                  helperText={
                    errors &&
                    errors.confirm_password &&
                    errors.confirm_password.message
                  }
                  autoComplete="passwordConfirmation"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className="!w-[90%] !mx-auto !mt-2">
            <Button
              fullWidth
              className="!text-lg"
              // onClick={handleForgetPassword}
              type="submit"
            >
              reset Password
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
