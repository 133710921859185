import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore/lite";
import { getDatabase } from "firebase/database";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "pwave-3b238.firebaseapp.com",
  databaseURL: "https://pwave-3b238-default-rtdb.firebaseio.com",
  projectId: "pwave-3b238",
  storageBucket: "pwave-3b238.appspot.com",
  messagingSenderId: "99886797334",
  appId: "1:99886797334:web:a17208a5ac6ebc97b41b37",
  measurementId: "G-1BMEHK3H98",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);

// export const requestForToken = () => {
//   return getToken(messaging, {
//     vapidKey:
//       "BO24_2jaCaBG6BzYQajkSe65YCoHCwhssdrSbWjGCaJlNhz_9DH45EOEQVK3z6NeBSCitlQjCqvJI7y84FEP4fw",
//   })
//     .then((currentToken) => {
//       if (currentToken) {
//         console.log("current token for client: ", currentToken);
//         // Perform any other neccessary action with the token
//       } else {
//         // Show permission request UI
//         console.log(
//           "No registration token available. Request permission to generate one."
//         );
//       }
//     })
//     .catch((err) => {
//       console.log("An error occurred while retrieving token. ", err);
//     });
// };
// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     onMessage(messaging, (payload) => {
//       console.log("payload", payload);
//       resolve(payload);
//     });
//   });
