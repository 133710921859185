import { Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./Footer.css";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import PWaveLogoImg from "assests/image/pWave.png";
import Facebook from "assests/icons/Facebook.png";
import Instagram from "assests/icons/Instagram.png";
import LinkedIn from "assests/icons/LinkedIn.png";
import Twitter from "assests/icons/Twitter.png";
import YouTube from "assests/icons/YouTube.png";
import Apple from "assests/icons/appstore.svg";
import GooglePlay from "assests/icons/googleplay.svg";
import Globe from "assests/icons/Globe.svg";
import Inc from "assests/icons/inc.svg";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import Routes from "routes/RoutesConstants";
import { NavLink } from "react-router-dom";
const EmailSubmitInput = () => {
  return (
    <OutlinedInput
      id="outlined-adornment-weight"
      endAdornment={<InputAdornment position="end">kg</InputAdornment>}
      aria-describedby="outlined-weight-helper-text"
      inputProps={{
        "aria-label": "weight",
      }}
    />
  );
};

const FooterContent = [
  {
    label: "p-Wave",
    children: [
      {
        linkDisplayName: "Home",
        linkAddress: Routes.REDIRECTOR,
      },
      {
        linkDisplayName: "Why p Wave",
        linkAddress: "/",
      },

      {
        linkDisplayName: "Contact Us",
        linkAddress: Routes.CONTACT_US,
      },
    ],
  },
];

const contactUsObject = {
  label: "Not Quite Ready For p-Wave?",

  children: [
    {
      linkDisplayName: "Join our online community for free. No spam. Ever.",
      linkAddress: "/",
    },

    {
      linkDisplayName: "",
      displayContent: <EmailSubmitInput />,
      linkAddress: "/",
    },
  ],
};

const FooterDisplay = (ContentDetails, isMdScreen, isSmScreen, pathname) => {
  const { label, children, location } = ContentDetails;
  return (
    <Grid item xs={12} sm={6} md={2} sx={{ ml: "auto" }} key={label}>
      <Typography
        align={isMdScreen ? "center" : "left"}
        className="footer-col-heading !font-bold"
      >
        {label}
      </Typography>
      {children.map((childrenLink, childrenIndex) => {
        return (
          <>
            {" "}
            {childrenLink.linkDisplayName ? (
              <Link to={childrenLink.linkAddress}>
                <Typography
                  align={isMdScreen ? "center" : "left"}
                  className="cursor-pointer footer-col-hyperlink"
                  key={
                    childrenLink.linkDisplayName ||
                    `footer-link-${childrenIndex}`
                  }
                >
                  {childrenLink.linkDisplayName}
                </Typography>
              </Link>
            ) : (
              <> </>
            )}
          </>
        );
      })}
    </Grid>
  );
};

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const isLoggedIn = Boolean(JSON.parse(localStorage.getItem("login")));

  const [isLoggedInState, setIsLoggedInState] = useState(isLoggedIn);

  useEffect(() => {
    setIsLoggedInState(isLoggedIn);
  }, [isLoggedIn]);
  const navigate = useNavigate();
  const location = useLocation();
  const [isPadding, setIsPadding] = useState(false);
  const login = localStorage.getItem("login");

  const isSmScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isMdScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const displayInformationSection = useMemo(() => {
    return (
      window.location.pathname === "/" ||
      window.location.pathname === "/contact-us"
    );
  }, [navigate]);
  const footerChildren =
    location.pathname === "/contact-us"
      ? [...FooterContent, contactUsObject]
      : FooterContent;
  return (
    <div className="footerContainer Section-Content">
      {(location.pathname === "/" || location.pathname === "/contact-us") && (
        <>
          <div
            style={{
              paddingTop: location.pathname === "/" && !isLoggedInState && 200,
            }}
            className="footerInformationSection"
          >
            <Grid
              container
              justifyContent={"sapce-between"}
              spacing={isSmScreen ? 0 : 2}
            >
              <Grid
                item
                xs={12}
                sm={4}
                md={location.pathname === "/contact-us" ? 4 : 6}
                align="left"
              >
                <div className="flex flex-col  footer-logo-container">
                  <div className="w-[100px]">
                    <img src={PWaveLogoImg} alt="p-wave logo" />{" "}
                  </div>
                </div>
              </Grid>

              {footerChildren.map((ContentDetails) =>
                FooterDisplay(
                  ContentDetails,
                  isMdScreen,
                  isSmScreen,
                  location.pathname
                )
              )}
            </Grid>
          </div>
        </>
      )}
      <>
        {location.pathname !== "/login" && (
          <Grid
            container
            justifyContent={"space-around"}
            className="footerSocialSection"
            alignItems="center"
            sx={{ py: 3 }}
          >
            <Grid item>
              <Grid container columnSpacing={1} alignItems="center">
                <Grid item>
                  <img src={Inc} />
                </Grid>
                <Grid item>
                  <Typography
                    variant="subtitle1"
                    align="center"
                    className="!text-[#102040] !text-[18px] !font-semibold "
                  >
                    {currentYear} Trapped Anonymous, Inc
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {location?.pathname === "/" && (
              <Grid item>
                <NavLink to="/privacy-policy">
                  <Typography
                    variant="subtitle1"
                    align="center"
                    className="!text-[#102040] !text-[18px] !font-semibold "
                  >
                    Terms & Privacy
                  </Typography>
                </NavLink>
              </Grid>
            )}
            {/* <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  <img src={Facebook} width={"22px"} />
                </Grid>
                <Grid item>
                  <img className="social-icon" src={Instagram} />
                </Grid>
                <Grid item>
                  <img className="social-icon" src={Twitter} />
                </Grid>
                <Grid item>
                  <img className="social-icon" src={LinkedIn} />
                </Grid>
                <Grid item>
                  <img className="social-icon" src={YouTube} />
                </Grid>
              </Grid>
            </Grid> */}
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  <img src={Apple} />
                  {/* <Grid
                container
                justifyContent="space-betweeen"
                alignItems="center"
              >
                <Grid item>
                  <img src={Apple} />
                </Grid>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography>Download on the</Typography>
                    </Grid>
                    <Grid item>
                      <Typography>App Store</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid> */}
                </Grid>
                <Grid item>
                  <img src={GooglePlay} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </>
    </div>
  );
};

export default Footer;
