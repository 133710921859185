import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useAPI, useToast } from "services";

// Sign up

const HandleEditProfile = (body) => {
  const method = "PATCH";
  const url = `api/users/update-profile`;
  return useAPI({ method, url, body });
};

export const HandleEditProfileAPI = () => {
  const navigate = useNavigate();
  const responseToast = useToast();
  /**
   * Please refer to "https://react-query.tanstack.com/reference/useMutation#_top"
   */
  return useMutation(HandleEditProfile, {
    onSuccess: (response) => {
      responseToast.toastSuccess(
        response.description || "Profile Updated Successfully"
      );

      // ^ redirect the user after sign up page
    },
    onError: (error) => {
      if (error && error.error) {
        responseToast.toastError(error.error || "Error while updating user");
      }
    },
  });
};
const HandleChangePassword = (body) => {
  const method = "POST";
  const url = `api/auth/change-password`;
  return useAPI({ method, url, body });
};

export const HandleChangePasswordAPI = () => {
  const navigate = useNavigate();
  const responseToast = useToast();
  /**
   * Please refer to "https://react-query.tanstack.com/reference/useMutation#_top"
   */
  return useMutation(HandleChangePassword, {
    onSuccess: (response) => {
      responseToast.toastSuccess(
        response.description || "Password Updated Successfully"
      );

      // ^ redirect the user after sign up page
    },
    onError: (error) => {
      if (error && error.error) {
        responseToast.toastError(
          error.error || "Error while password user",
          error
        );
      }
    },
  });
};
const ChangePomodoriallTiming = (body) => {
  const method = "PATCH";
  const url = `api/users/pomodorial-timing`;
  return useAPI({ method, url, body });
};

export const ChangePomodoriallTimingAPI = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const responseToast = useToast();
  /**
   * Please refer to "https://react-query.tanstack.com/reference/useMutation#_top"
   */
  return useMutation(ChangePomodoriallTiming, {
    onSuccess: (response) => {
      responseToast.toastSuccess(
        response.description || "Timing Updated Successfully"
      );
      queryClient.invalidateQueries(["GET_LOGGED_IN_USER_DETAILS"]);

      // ^ redirect the user after sign up page
    },
    onError: (error) => {
      if (error && error.error) {
        responseToast.toastError(
          error.error_message || "Error while updated timing",
          error
        );
      }
    },
  });
};
export const FetchUserSubscriptions = () => {
  const method = "GET";
  const url = `api/subscription`;
  return useAPI({ method, url });
};

export const FetchUserSubscriptionsAPI = () => {
  const navigate = useNavigate();
  return useQuery("GET_LOGGED_IN_USER_SUBSCRIPTIONS", FetchUserSubscriptions, {
    enabled: true,
    onError: (error) => {
      if (error) {
        // localStorage.clear();
      }
    },
  });
};

// CONTACT US

const HandleContactUsForm = (body) => {
  const method = "POST";
  const url = `api/users/contact-us`;
  return useAPI({ method, url, body });
};

export const useHandleContactUsForm = () => {
  return useMutation(HandleContactUsForm);
};
