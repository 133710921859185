import { useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "./PaymentForm";
import PaymentPageImage from "assests/image/payment/PaymentPage.svg";
import CardType from "assests/image/payment/CardType.svg";
import SecureLock from "assests/image/payment/SecureLock.svg";
import { Grid, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TrailForm from "./TrailForm";
import { useState } from "react";
import { CreatePaymentIntentAPI, AddCardAPI } from "./PaymentQueries";

function FreeTrailPage() {
  const { mutate: getStipePaymentItnent, data: paymentIntentDetails } =
    CreatePaymentIntentAPI();

  const [openPaymentForm, setOpenPaymentForm] = useState(false);
  const handleOpenPaymentForm = () => {
    setOpenPaymentForm(true);
  };
  const stripePromise = loadStripe(
    `${paymentIntentDetails?.data?.publishable_key}`
  );
  console.log(paymentIntentDetails);
  useEffect(() => {
    getStipePaymentItnent();
  }, []);

  return (
    <div className="h-full py-8 App-Container">
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        className="my-auto h-full"
      >
        <Grid item xs={4} lg={5}>
          <img src={PaymentPageImage} alt="payment illustration" />
        </Grid>
        <Grid item xs={6} lg={5}>
          {!openPaymentForm ? (
            <div onClick={handleOpenPaymentForm}>
              <TrailForm handleOpenPaymentForm={handleOpenPaymentForm} />
            </div>
          ) : (
            <Elements stripe={stripePromise}>
              {<PaymentForm paymentIntentDetails={paymentIntentDetails} />}
            </Elements>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default FreeTrailPage;
