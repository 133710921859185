import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, Typography } from "@mui/material";
import LabelledTextField from "components/LabelledTextField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import LabelledTextFieldWithAdornments from "components/LabelledTextFieldWithAdornments";
import {
  HandleForgotPasswordApi,
  HandleResetPasswordApi,
} from "./LoginQueries";

export default function ForgotPassword(props) {
  const {
    openResetModal,
    setOpenResetModal,
    token,

    schema,
  } = props;
  const {
    register,
    handleSubmit,
    watch: loginFormCredentials,
    formState: { errors },
    clearErrors,
    reset,
    setFocus,
  } = useForm({ resolver: yupResolver(schema) });

  const { ref: refRegisterPassword, ...RegisterLoginPassword } =
    register("password");
  const {
    ref: refRegisterPasswordConfirmation,
    ...RegisterLoginPasswordConfirmation
  } = register("confirm_password");

  const handleClose = () => {
    clearErrors();
    reset();
    setOpenResetModal(false);
    forgotPasswordReset();
  };

  const {
    mutate: forgotPasswordUpdate,
    reset: forgotPasswordReset,
    status: forgotPasswordStatus,
    error: forgotPasswordError,
  } = HandleForgotPasswordApi();
  const resetPasswordApi = HandleResetPasswordApi();

  const handleResetPassword = (data) => {
    let tempData = {};

    tempData.token = token;
    tempData.password = data.password;
    tempData.confirm_password = data.confirm_password;

    resetPasswordApi.mutate(tempData);
    handleClose();
  };
  const handleResetPasswordError = (data) => {
    console.log();
    forgotPasswordReset();
  };

  return (
    <div className="px-12">
      <Dialog
        fullWidth={true}
        maxWidth={"xs"}
        open={openResetModal}
        onClose={handleClose}
      >
        <DialogTitle sx={{ mt: "-2px" }}>
          <Typography
            id="modal-modal-title"
            variant="h4"
            align="center"
            gutterBottom={true}
            component="h2"
            className="!font-semibold"
          >
            p Wave
          </Typography>
        </DialogTitle>
        <form
          onSubmit={handleSubmit(handleResetPassword, handleResetPasswordError)}
        >
          <DialogContent>
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                <LabelledTextFieldWithAdornments
                  label="Password"
                  type="password"
                  inputRef={refRegisterPassword}
                  {...RegisterLoginPassword}
                  error={Boolean(errors.password)}
                  helperText={
                    errors && errors.password && errors.password.message
                  }
                  autoComplete="password"
                />
              </Grid>
              <Grid item xs={12}>
                <LabelledTextFieldWithAdornments
                  label="Confirm Password"
                  type="password"
                  inputRef={refRegisterPasswordConfirmation}
                  {...RegisterLoginPasswordConfirmation}
                  error={Boolean(errors.confirm_password)}
                  helperText={
                    errors &&
                    errors.confirm_password &&
                    errors.confirm_password.message
                  }
                  autoComplete="passwordConfirmation"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className="!w-[90%] !mx-auto !mt-2">
            <Button
              fullWidth
              className="!text-lg"
              // onClick={handleForgetPassword}
              type="submit"
            >
              Reset Password
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
