import React from "react";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import {
  Eventcalendar,
  CalendarPrev,
  CalendarNav,
  CalendarNext,
  CalendarToday,
  SegmentedGroup,
  SegmentedItem,
  getJson,
  setOptions,
} from "@mobiscroll/react";
import "./styles.css";

setOptions({
  theme: "material",
  themeVariant: "light",
});

function YearlyCalendar({ events, mainSelectedDate, setMainSelectedDate }) {
  const [calendarType, setCalendarType] = React.useState("quarter");
  const [myEvents, setEvents] = React.useState([]);
  const handleDateChange = (event, inst) => {
    setMainSelectedDate({ date: event.date, type: "Day" });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const view = React.useMemo(
    () =>
      calendarType === "quarter"
        ? {
            calendar: {
              type: "month",
              size: 3,
            },
          }
        : {
            calendar: {
              type: "year",
            },
          },
    [calendarType]
  );

  const calendarHeaderSwitch = () => {
    return <React.Fragment></React.Fragment>;
  };

  return (
    <Eventcalendar
      view={{
        calendar: {
          type: "year",
          outerDays: false,
          size: 1,
        },
      }}
      cssClass="yearCalendar"
      dayNamesMin={["So", "Mo", "Tu", "We", "Th", "Fr", "Sa"]}
      height={"100%"}
      exclusiveEndDates={true}
      renderHeader={calendarHeaderSwitch}
      selectedDate={mainSelectedDate}
      colors={events}
      onCellClick={(event, inst) => handleDateChange(event, inst)}
    />
  );
}

export default YearlyCalendar;
