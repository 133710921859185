import {
  AddBusiness,
  Category as CategoryIcon,
  Inventory as InventoryIcon,
  LocalMall,
  LocalOffer,
  ManageAccounts,
  SupportAgent,
} from "@mui/icons-material";

const Routes = {
  REDIRECTOR: `/`,
  LOGIN: `/login`,
  SIGNUP: "/signup",
  CONTACT_US: "/contact-us",
  PROFILE: "/profile",

  // Calendars
  CALENDAR_SETUP: "/calendar/setup",
  CALENDAR_PREVIEW: "/calendar/preview",
  PWAVECALENDAR: "/p-wave-calendar",
  MINDMAP: "/mind-map",
  MINDMAPWEBVIEW: "/mindmap/web",
  VIDEO: "/videos",
  FREETRAIL: "/free-trail",
  NOTES: "/notes",
  PRIVACY_POLICY: "/privacy-policy",
};

export const NavBarLinks = [
  {
    name: "",
    icon: (props) => {
      return <ManageAccounts {...props} />;
    },
  },
];

export const AppBarLinks = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "Why p Wave?",
    link: "https://www.trappedanonymous.com/p-wave-app/",
    target: "_blank",
  },
  {
    name: "Why Trapped Anonymous",
    link: "https://www.trappedanonymous.com/",
    target: "_blank",
  },
  {
    name: "Contact Us",
    link: Routes.CONTACT_US,
  },
  {
    name: "Login",
    link: Routes.LOGIN,
  },
];

export default Routes;
