import React, { useEffect } from "react";

import Dialog from "@mui/material/Dialog";
import { Button, Grid, Typography } from "@mui/material";
import {
  BuySubscriptionApi,
  DeleteSubscriptionApi,
} from "containers/LoginPage/LoginQueries";

export default function SubscriptionDialog(props) {
  const { openDialog, setOpenDialog, handleClose, status } = props;
  const { mutate: getSubscription } = BuySubscriptionApi();
  const { mutate: deleteSubscription } = DeleteSubscriptionApi();
  const handleConfirm = () => {
    if (status === "Activate") {
      getSubscription();
      handleClose();
    } else {
      deleteSubscription();
      handleClose();
    }
  };

  return (
    <Dialog
      maxWidth={"xs"}
      open={openDialog}
      onClose={handleClose}
      PaperProps={{
        sx: {
          overflow: "visible",
        },
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className="gap-12"
      >
        <Grid item>
          <Typography align="center" variant="h6">
            Are you sure you want to {status} your membership?
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          alignItems="center"
          spacing={4}
        >
          <Grid item xs={4}>
            <Button fullWidth variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button fullWidth onClick={handleConfirm}>
              Confirm
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}
