import * as React from "react";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Divider, Grid, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Profile from "assests/image/Default_pfp.svg";
import LabelledTextFieldWithAdornments from "components/LabelledTextFieldWithAdornments";
import EditIcon from "@mui/icons-material/Edit";
import * as yup from "yup";
import {
  FetchUserDetailsAPI,
  UpdateProfilePhotoAPI,
} from "containers/LoginPage/LoginQueries";
import {
  FetchUserSubscriptionsAPI,
  HandleEditProfileAPI,
} from "./EditProfileQueries";
import { Link } from "react-router-dom";
import Routes from "routes/RoutesConstants";
import { FileUploadComponent } from "components";
import ResetPassword from "./ChangePassword";
import { useMemo } from "react";
import ChangeCardDialog from "./ChangeCardDialog";
import { GetCardDetailsAPI } from "containers/FreeTrail/PaymentQueries";
import Visa from "assests/icons/visa.svg";
import MasterCard from "assests/icons/mc_symbol.svg";
import SubscriptionDialog from "./Subscription";

const userValidSchema = yup.object().shape({
  name: yup
    .string()
    .required("Name is a required field")
    .min(2, "Name length must be min of 2 alphabets")
    .max(26, "Name length must be max of 26 alphabets")
    .matches(/^[aA-zZ\s]+$/, "Please enter a Valid Name"),

  phone: yup
    .string()
    .required("Phone No is a required field")
    .min(16, "Phone No. cannot be less than 10 Digits")
    .max(16, "Phone No. cannot be more than 10 Digits")
    .matches(
      /(?:\(?\+\d{2}\)?\s*)?\d+(?:[ -]*\d+)*$/,
      "Please Enter a valid Phone No"
    ),
});
const newPasswordSchema = yup.object().shape({
  current_password: yup.string().required("Old password is a required field"),
  new_password: yup
    .string()
    .required("Password is a required field")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("new_password"), null], "Passwords must match"),
});
const newPasswordSchemaWithoutOld = yup.object().shape({
  new_password: yup
    .string()
    .required("Password is a required field")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("new_password"), null], "Passwords must match"),
});

export default function ProfilePage(props) {
  const { setEditProfile, editProfile } = props;
  const { mutate: editProfileImageApi } = UpdateProfilePhotoAPI();

  const {
    register,
    handleSubmit,
    watch: loginFormCredentials,
    formState: { errors },
    clearErrors,
    setFocus,
    control,
    reset,
    setValue,
  } = useForm({ resolver: yupResolver(userValidSchema) });
  const [image, setImage] = React.useState(null);
  const [userImage, setUserImage] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [openSubscription, setOpenSubscription] = React.useState(false);
  const { data: subscription } = FetchUserSubscriptionsAPI();
  console.log(subscription);

  const { ref: refRegisterEmail, ...RegisterLoginEmail } = register("email");
  const { ref: refRegisterName, ...RegisterLoginName } = register("name");
  const { ref: refRegisterPassword, ...RegisterLoginPassword } = register(
    "phone"
  );
  const {
    data: userDetails,
    refetch: refetchUerDetails,
    dataUpdatedAt,
    refetch,
    isFetching,
  } = FetchUserDetailsAPI();

  const {
    mutate: editUserProfile,
    isSuccess: editProfileSuccess,
  } = HandleEditProfileAPI();
  const {
    data: cardDetails,
    refetch: refetchCardDetails,
  } = GetCardDetailsAPI();

  console.log(cardDetails);
  const [openAddCardDialog, setOpenAddCardDialog] = React.useState(false);
  const isCardAdded = useMemo(() => {
    return userDetails?.data?.user?.is_card_added ?? false;
  }, [dataUpdatedAt]);
  const isNumericInput = (event) => {
    const key = event.keyCode;
    return (
      (key >= 48 && key <= 57) || // Allow number line
      (key >= 96 && key <= 105) // Allow number pad
    );
  };

  const isModifierKey = (event) => {
    const key = event.keyCode;
    return (
      event.shiftKey === true ||
      key === 35 ||
      key === 36 || // Allow Shift, Home, End
      key === 8 ||
      key === 9 ||
      key === 13 ||
      key === 46 || // Allow Backspace, Tab, Enter, Delete
      (key > 36 && key < 41) || // Allow left, up, right, down
      // Allow Ctrl/Command + A,C,V,X,Z
      ((event.ctrlKey === true || event.metaKey === true) &&
        (key === 65 || key === 67 || key === 86 || key === 88 || key === 90))
    );
  };

  const enforceFormat = (event) => {
    // Input must be of a valid number format or a modifier key, and not longer than ten digits
    if (!isNumericInput(event) && !isModifierKey(event)) {
      event.preventDefault();
    }
  };
  const handleClsoeSubscription = () => {
    setOpenSubscription(false);
  };

  const formatToPhone = (event) => {
    if (isModifierKey(event)) {
      return;
    }

    // I am lazy and don't like to type things more than once
    const target = event.target;
    const input = event.target.value.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
    const zip = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);

    if (input.length > 6) {
      setValue("phone", `(${zip}) ${middle} - ${last}`, {
        shouldValidate: false,
      });
    } else if (input.length > 3) {
      setValue("phone", `(${zip}) ${middle}`, { shouldValidate: false });
    } else if (input.length > 0) {
      setValue("phone", `(${zip}`, { shouldValidate: false });
    }
  };
  React.useEffect(() => {
    if (Boolean(image)) {
      editProfileImageApi(image.imageId);
      // setTimeout(() => {
      //   window.location.reload();
      // }, 500);
    }
  }, [image]);

  React.useEffect(() => {
    if (Boolean(editProfileSuccess)) {
      refetchUerDetails();
    }
  }, [editProfileSuccess]);
  React.useEffect(() => {
    if (Boolean(userDetails?.data?.user)) {
      setUserImage(userDetails?.data?.user?.profile_image?.image_path);
      setValue("email", userDetails?.data?.user?.email, {
        shouldValidate: false,
      });
      setValue("name", userDetails?.data?.user?.name, {
        shouldValidate: false,
      });

      const event = { target: { value: userDetails?.data?.user?.phone } };
      userDetails?.data?.user?.phone && formatToPhone(event);
    }
  }, [userDetails]);
  console.log(userDetails);
  const isMemberShipActive = useMemo(
    () => userDetails?.data?.user?.is_membership_active,
    [userDetails?.data?.user?.is_membership_active]
  );
  const initialTextField = [
    {
      label: "Email",
      ref: refRegisterEmail,
      spreadRef: { ...RegisterLoginEmail },
      name: "email",
      disabled: true,
      type: "text",
    },

    {
      label: "NAME",
      ref: refRegisterName,
      spreadRef: { ...RegisterLoginName },
      name: "name",
      disabled: true,
      type: "text",
    },

    {
      label: "PHONE NO.",
      ref: refRegisterPassword,
      spreadRef: { ...RegisterLoginPassword },
      name: "phone",
      disabled: true,
      onChange: formatToPhone,
      type: "text",
    },
  ];

  const [textFieldData, setTextFeildData] = React.useState(initialTextField);
  const handleInputDisabled = (i) => {
    setTextFeildData((data) =>
      data.map((val, index) =>
        i === index ? { ...val, disabled: false } : val
      )
    );
  };
  const { mutate: updatePhoto } = UpdateProfilePhotoAPI();

  const handleEditForm = (data) => {
    const postData = { ...data };
    let phoneString = data.phone.match(/\d/g).join("");

    postData.phone = phoneString;
    editUserProfile(postData);

    handleClose();
  };
  const handleEditErrorFrom = (data) => {
    //
  };

  const handleClose = () => {
    setTextFeildData(initialTextField);
    setEditProfile(false);
    clearErrors();
    reset();
  };
  const handleImgUploadSuccess = (imageData) => {
    // update photo
    updatePhoto(imageData.id, {
      onSuccess: (resp) => {
        //
        // either save the image data or r
        //  queryClient.invalidateQueries("GET_LOGGED_IN_USER_DETAILS");
      },
    });
  };

  return (
    <div className="px-12 h-full flex justify-center items-center py-12 flex-col">
      <div className="w-9/12">
        <DialogTitle className="">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography className="!font-semibold" variant="h5">
                General Settings
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider sx={{ borderBottomWidth: 1 }} variant="middle" />
        <form
          onSubmit={handleSubmit(handleEditForm, handleEditErrorFrom)}
          id="login-form"
        >
          <div className="mt-12 ">
            <Grid
              container
              justifyContent="space-between"
              columnSpacing={8}
              alignItems="center"
            >
              <Grid item>
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  rowSpacing={2}
                >
                  <Grid item>
                    <img
                      src={
                        image?.thumbUrl
                          ? image?.thumbUrl
                          : userImage
                          ? userImage
                          : Profile
                      }
                      alt="profile"
                      width="135"
                      className="rounded-full"
                    />
                  </Grid>
                  <Grid item>
                    <FileUploadComponent
                      label=""
                      image={image}
                      setImage={setImage}
                      type="profile"
                      onSuccessHandler={handleImgUploadSuccess}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs>
                <Grid container direction="column" rowSpacing={4}>
                  {textFieldData.map((val, i) => {
                    return (
                      <Grid item key={i}>
                        <Grid
                          container
                          columns={16}
                          columnSpacing={2}
                          alignItems="center"
                        >
                          <Grid item xs={3}>
                            <Typography
                              color="secondaryBlack.main"
                              variant="body2"
                              className="!font-semibold"
                            >
                              {val.label}
                            </Typography>
                          </Grid>
                          <Grid item xs>
                            <LabelledTextFieldWithAdornments
                              inputRef={val.ref}
                              {...val.spreadRef}
                              disabled={val.disabled}
                              error={Boolean(errors?.[val.name])}
                              helperText={errors?.[val.name]?.message}
                              autoComplete="text"
                              onChange={val.onChange}
                              type={val.type}
                              endAdornment={
                                val.name == "email" || (
                                  <EditIcon
                                    fontSize="small"
                                    className="cursor-pointer"
                                    onClick={() => handleInputDisabled(i)}
                                  />
                                )
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </div>
          <Grid container className="!my-4" justifyContent="flex-end" item>
            <Typography
              variant="h6"
              color="primaryBlack.main"
              align="right"
              onClick={() => setOpenModal(true)}
              className="cursor-pointer !text-[16px]"
            >
              Change password
            </Typography>
          </Grid>

          <DialogActions className="!my-2 !pb-6 !pr-2">
            <Grid
              container
              justifyContent="flex-end"
              columnSpacing={4}
              alignItems="center"
            >
              <Grid item>
                <Button type="submit" size="large">
                  Update
                </Button>
              </Grid>
              <Grid item>
                <Link to={Routes.REDIRECTOR}>
                  <Button onClick={handleClose} variant="outlined" size="large">
                    Cancel
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </div>
      <Divider fullWidth variant="fullWidth " />
      {isCardAdded && (
        <Grid container className="!w-9/12 !flex-col !items-start">
          <Grid item>
            <Typography className="!font-semibold" variant="h5">
              Card Details
            </Typography>
          </Grid>
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
            columns={13}
          >
            <Grid
              container
              xs={12}
              sm={6}
              justifyContent="space-around"
              alignItems="center"
              item
              className="!bg-[#E8EDEF] !py-4 !mt-6  !min-h-[42.5px]"
            >
              <Grid item>
                <img
                  src={
                    cardDetails?.data?.[0]?.card_issuer === "visa"
                      ? Visa
                      : MasterCard
                  }
                  alt="visa"
                  width={60}
                />
              </Grid>
              <Grid item>
                <Typography
                  variant="subtitle1"
                  color="secondaryBlack.main"
                  className="!opacity-80 text-[20px]"
                >
                  {cardDetails?.data?.[0]?.card_issuer}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="subtitle1"
                  color="secondaryBlack.main"
                  className="!opacity-80 text-[16px]"
                >
                  xxxx {cardDetails?.data?.[0]?.last_four_digits}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  className="!py-1 px-4"
                  onClick={() => setOpenAddCardDialog(true)}
                >
                  Update card
                </Button>
              </Grid>
            </Grid>
            <Grid
              item
              container
              justifyContent="space-around"
              alignItems="center"
              className="!bg-[#E8EDEF] !py-4 !mt-6  !min-h-[42.5px]"
              xs={12}
              sm={5}
            >
              {/* <div className="flex justify-around items-center px-2"> */}
              <Grid item>
                <Typography
                  variant="h6"
                  color="primaryBlack.main"
                  align="center"
                  // onClick={() => setOpenAddCardDialog(true)}
                  className=" !text-[16px]  top-[-30px] left-6 "
                >
                  Subscription
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="h6"
                  color="primaryBlack.main"
                  align="center"
                  // onClick={() => setOpenAddCardDialog(true)}
                  className=" !text-[16px] "
                >
                  {isMemberShipActive ? "Active" : "Inactive"}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  className="!py-1 px-4"
                  onClick={() => setOpenSubscription(true)}
                >
                  {isMemberShipActive ? "Deactivate" : "Activate"}
                </Button>
              </Grid>
              {/* </div> */}
            </Grid>
          </Grid>
        </Grid>
      )}
      {openModal && (
        <ResetPassword
          openModal={openModal}
          setOpenModal={setOpenModal}
          schema={
            userDetails?.data?.user?.is_current_password_required
              ? newPasswordSchema
              : newPasswordSchemaWithoutOld
          }
          isCurrentPasswordRequired={
            userDetails?.data?.user?.is_current_password_required
          }
        />
      )}
      {openAddCardDialog && (
        <ChangeCardDialog
          openDialog={openAddCardDialog}
          setOpenDialog={setOpenAddCardDialog}
          refetchCardDetails={refetchCardDetails}
        />
      )}
      {openSubscription && (
        <SubscriptionDialog
          openDialog={openSubscription}
          setOpenDialog={setOpenSubscription}
          handleClose={handleClsoeSubscription}
          status={isMemberShipActive ? "Deactivate" : "Activate"}
        />
      )}
    </div>
  );
}
