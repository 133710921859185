import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export function PWaveChart({
  preview,
  chartData,
  mindmap,
  height,
  previewTaskAdd,
}) {
  const [chartValue, setChartValue] = useState([]);
  const [labels, setLabels] = useState([]);
  useEffect(() => {
    if (Boolean(chartData)) {
      let step = [];

      let tempChartLabel = [];
      let tempChartValue = [];
      chartData?.selectHours?.forEach((key) => {
        tempChartValue = [...tempChartValue, key.selected_time_type];

        const time = key.selected_time.slice(0, 2) * 1;
        if (time < 13) {
          step = [...step, time];
          tempChartLabel = [...tempChartLabel, `${time} AM`];
        } else {
          step = [...step, time - 12];
          tempChartLabel = [...tempChartLabel, `${time - 12} PM`];
        }
      });
      setLabels(tempChartLabel);
      setChartValue(tempChartValue);
    }
  }, [chartData]);
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    animation: true,
    plugins: { legend: { display: false }, tooltip: false },

    elements: {
      line: {
        tension: 0.4,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: false,
          color: "#fff",
        },
        gridLines: {
          display: false,
        },
        ticks: {
          padding: 20,
        },
      },
      y: {
        grid: {
          display: false,
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: false,
          color: "#fff",
        },
        gridLines: {
          display: false,
        },
        ticks: {
          beginAtZero: true,
          steps: 4,
          stepSize: 1,
          max: 1,
          font: {
            weight: "bold",
          },

          padding: 20,

          callback: function (value, index, ticks) {
            if (previewTaskAdd) return;
            return value === 1
              ? "Low Enegry"
              : value === 2
              ? "Medium Energy"
              : value === 3
              ? "High Energy"
              : "Maximum Energy";
          },
        },
      },
    },
  };
  const value = chartValue?.map((val) =>
    val === "low_energy_time"
      ? 1
      : val === "med_energy_time"
      ? 2
      : val === "high_energy_time"
      ? 3
      : 4
  );

  // const labels = [
  //   "06:00AM",
  //   "07:00AM",
  //   "08:00AM",
  //   "09:00AM",
  //   "10:00AM",
  //   "11:00AM",
  //   "12:00PM",
  //   "01:00PM",
  //   "02:00PM",
  //   "03:00PM",
  //   "04:00PM",
  //   "05:00PM",
  //   "06:00PM",
  // ];

  // const labels = chartData.map((val) => val.selected_time);
  const PreviewData = {
    labels,
    datasets: [
      {
        label: "My First Dataset",
        data: value,
        fill: true,
        borderColor: "#102040",
        fillColor: "#DAE7EB",
        backgroundColor: "#DAE7EB",
        pointStyle: "circle",
        pointRadius: 0,
        pointHoverRadius: 0,
      },
    ],
  };
  const data = {
    labels,
    datasets: [
      {
        label: "",
        data: value,
        fill: true,
        borderColor: "#102040",
        backgroundColor: "#DAE7EB",
        strokeColor: "#DAE7EB",
        pointStyle: "circle",
        pointRadius: 7,
        pointHoverRadius: 10,
        pointBackgroundColor: (val) => {
          if (preview) {
            return;
          }

          const value = val?.raw;

          return chartData?.selectHours[val?.dataIndex]?.tasks?.[0]
            ?.task_level_id === 1
            ? "#0E9A35"
            : chartData?.selectHours[val?.dataIndex]?.tasks?.[0]
                ?.task_level_id === 2
            ? "#FBAD19"
            : chartData?.selectHours[val?.dataIndex]?.tasks?.[0]
                ?.task_level_id === 3
            ? "#E52630"
            : "#CDCDCD";
        },
      },
    ],
  };

  return (
    <div className="w-full " style={{ height: height || 350 }}>
      <Line options={options} data={preview ? PreviewData : data} />
    </div>
  );
}
