import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useAPI, useToast } from "services";
import Routes from "routes/RoutesConstants";
import { boolean } from "yup/lib/locale";

// Sign up

const HandleSignUp = (body) => {
  const method = "POST";
  const url = `api/auth/register`;
  return useAPI({ method, url, body });
};

export const HandleSignUpAPI = () => {
  const navigate = useNavigate();
  const responseToast = useToast();
  /**
   * Please refer to "https://react-query.tanstack.com/reference/useMutation#_top"
   */
  return useMutation(HandleSignUp, {
    onSuccess: (response) => {
      responseToast.toastSuccess(
        response.description || "Account created successfully"
      );
      localStorage.clear();
      localStorage.setItem("login", JSON.stringify(true));
      localStorage.setItem("token", response.data.access_token.token, () =>
        navigate(Routes.FREETRAIL)
      );
      localStorage.setItem("expires", response.data.access_token.expires);
      localStorage.setItem(
        "refresh_token",
        response.data.access_token.refresh_token
      );
      localStorage.setItem("user_role", JSON.stringify(response.data.role));
      localStorage.setItem("user_details", JSON.stringify(response.data.user));
      // navigate(Routes.FREETRAIL);

      // ^ redirect the user after sign up page
    },

    onError: (error) => {
      if (error) {
        console.log(error);
        responseToast.toastError(
          error.error_messages || "Error while creating user"
        );
      }
    },
  });
};

const ValidationSocialSignupDetails = (body) => {
  const method = "POST";
  const url = `api/auth/register`;
  return useAPI({ method, url, body });
};
export const HandleSocialSignupAPI = () => {
  const navigate = useNavigate();
  const responseToast = useToast();
  /**
   * Please refer to "https://react-query.tanstack.com/reference/useMutation#_top"
   */
  return useMutation(ValidationSocialSignupDetails, {
    onSuccess: (response) => {
      console.log("response", response);
      localStorage.clear();
      localStorage.setItem("login", JSON.stringify(true));
      localStorage.setItem("token", response.data.access_token.token);
      localStorage.setItem("expires", response.data.access_token.expires);
      localStorage.setItem(
        "refresh_token",
        response.data.access_token.refresh_token
      );
      localStorage.setItem("user_role", JSON.stringify(response.data.role));
      localStorage.setItem("user_details", JSON.stringify(response.data.user));
      responseToast.toastSuccess(
        response.description || "Logged In successfully"
      );
      response.data.user.is_membership_active
        ? navigate(Routes.REDIRECTOR)
        : navigate(Routes.FREETRAIL);
    },
    onError: (error) => {
      if (error) {
        responseToast.toastError(
          error.error_messages || "Error occured while signing up",
          error
        );
      }
    },
  });
};

// Login

const ValidationLoginDetails = (body) => {
  const method = "POST";
  const url = `api/auth/login`;
  const payload = {
    ...body,
  };
  return useAPI({ method, url, body: { ...payload } });
};

export const HandleLoginFormAPI = () => {
  const navigate = useNavigate();
  const responseToast = useToast();
  /**
   * Please refer to "https://react-query.tanstack.com/reference/useMutation#_top"
   */
  return useMutation(ValidationLoginDetails, {
    onSuccess: (response) => {
      localStorage.clear();
      localStorage.setItem("login", JSON.stringify(true));
      localStorage.setItem("token", response.data.access_token.token);
      localStorage.setItem("expires", response.data.access_token.expires);
      localStorage.setItem(
        "refresh_token",
        response.data.access_token.refresh_token
      );
      localStorage.setItem("user_role", JSON.stringify(response.data.role));
      localStorage.setItem("user_details", JSON.stringify(response.data.user));
      responseToast.toastSuccess(
        response.description || "Logged In successfully"
      );
      navigate(Routes.REDIRECTOR);
    },
    onError: (error) => {
      if (error) {
        console.log(error);
        responseToast.toastError(
          error.error_messages || "Password is incorrect"
        );
      }
    },
  });
};

export const UploadImageAPI = () => {
  const navigate = useNavigate();
  const responseToast = useToast();
  /**
   * Please refer to "https://react-query.tanstack.com/reference/useMutation#_top"
   */
  return useMutation(UploadImage, {});
};

const UploadImage = (body) => {
  const method = "POST";
  const url = `api/images?type=${body.type}`;
  let formData = new FormData();
  console.log(body);
  formData.append("file", body?.file);

  return useAPI({
    method,
    url,
    body: formData,
    contentType: "multipart/form-data",
    headers: {
      Accept: "*/*",
    },
  });
};
const UpdateProfilePhoto = (imgId) => {
  const method = "PATCH";
  const url = `api/users/update-profile-image`;
  const payload = {
    profile_image: imgId * 1,
  };
  console.log("test");
  return useAPI({ method, url, body: payload });
};
export const UpdateProfilePhotoAPI = () => {
  console.log("test");
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const responseToast = useToast();
  /**
   * Please refer to "https://react-query.tanstack.com/reference/useMutation#_top"
   */
  return useMutation(UpdateProfilePhoto, {
    onSuccess: (response) => {
      responseToast.toastSuccess("Successfully updated profile photo");
      queryClient.invalidateQueries("GET_LOGGED_IN_USER_DETAILS");
      setTimeout(() => {
        window.location.reload();
      }, 500);
    },
    onError: (error) => {
      if (error) {
        console.log("Error is ", error);
        responseToast.toastError(
          error.error_message || "Error occured while updating profile photo",
          error
        );
      }
    },
  });
};
export const UploadImageQuery = () => {
  const responseToast = useToast();
  return useMutation(UploadImage, {
    onSuccess: (response) => {
      console.log(response);

      // responseToast.toastSuccess(response.message);
    },
    onError: (error) => responseToast.toastError(error && error.error_message),
  });
};

// //////Refresh token

const RefreshAccessToken = (payload) => {
  const method = "POST";
  const url = `api/auth/refresh-token`;
  return useAPI({ method, url, body: { ...payload } });
};

export const HandleRefreshAccesTokenAPI = () => {
  const responseToast = useToast();
  return useMutation(RefreshAccessToken, {
    onSuccess: (response) => {
      console.log("Response", response)
      localStorage.setItem("token", response.data.accessToken);
      // localStorage.setItem("expires", response.data.expires);
      localStorage.setItem("refresh_token", response.data.refreshToken);
      localStorage.setItem("login", JSON.stringify(true));
    },
    retry: 3,
  });
};

const ForgotPassword = (body) => {
  const method = "POST";
  const url = `api/auth/forgot-password`;
  const payload = {
    ...body,
  };
  return useAPI({ method, url, body: { ...payload } });
};

export const HandleForgotPasswordApi = () => {
  const responseToast = useToast();
  return useMutation(ForgotPassword, {
    onSuccess: (res) => {
      console.log("Response====>>>>>", res);
    },
    onError: (err) => {
      responseToast.toastError(err?.error || "Error while changing password");
    },
  });
};
const RestPassword = (body) => {
  const method = "POST";
  const url = `api/auth/reset-password`;
  const payload = {
    ...body,
  };
  return useAPI({ method, url, body: { ...payload } });
};

export const HandleResetPasswordApi = () => {
  const responseToast = useToast();
  const navigate = useNavigate();
  return useMutation(RestPassword, {
    onSuccess: (res) => {
      console.log("Response====>>>>>", res);
      responseToast.toastSuccess("Password has been changed Successfully");
    },
    onError: (err) => {
      responseToast.toastError(err?.error || "Error while changing password");
    },
  });
};
const BuySubscription = (body) => {
  const method = "POST";
  const url = `api/subscription`;
  const payload = {
    ...body,
  };
  return useAPI({
    method,
    url,
    body: { price: process.env.REACT_APP_STRIPE_PRICE_ID },
  });
};

export const BuySubscriptionApi = () => {
  const responseToast = useToast();
  const queryClient = useQueryClient();
  return useMutation(BuySubscription, {
    onSuccess: (res) => {
      console.log(res);
      queryClient.invalidateQueries(["GET_LOGGED_IN_USER_DETAILS"]);
      const mobileDeepLink = process.env.REACT_APP_DEEP_LINK;
      if (mobileDeepLink) {
        window.location.href = mobileDeepLink;
      }

    },
    onError: (error) => {
      if (error && error.error) {
        responseToast.toastError(
          error.message || "Error in subscription",
          error
        );
      }
    },
  });
};
const DeleteSubscription = (body) => {
  const method = "DELETE";
  const url = `api/subscription`;

  return useAPI({
    method,
    url,
  });
};

export const DeleteSubscriptionApi = () => {
  const queryClient = useQueryClient();
  return useMutation(DeleteSubscription, {
    onSuccess: (res) => {
      queryClient.invalidateQueries(["GET_LOGGED_IN_USER_DETAILS"]);
    },
    onError: (err) => { },
  });
};

export const FetchUserDetails = () => {
  const method = "GET";
  const url = `api/auth/me`;
  return useAPI({ method, url });
};

export const FetchUserDetailsAPI = () => {
  const navigate = useNavigate();
  return useQuery("GET_LOGGED_IN_USER_DETAILS", FetchUserDetails, {
    enabled: Boolean(localStorage.getItem('token')),
    onSuccess: (res) =>
      localStorage.setItem("user_details", JSON.stringify(res.data.user)),
    onError: (error) => {
      if (error) {
        // localStorage.clear();
      }
    },
  });
};
