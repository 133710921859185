import { ButtonBase, Grid } from "@mui/material";
import React, { useEffect } from "react";

import { useGoogleLogin } from "react-google-login";
import { useLocation, useNavigate } from "react-router-dom";
import Routes from "routes/RoutesConstants";
import { HandleSocialSignupAPI } from "./LoginQueries";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import FacebookLoginImg from "assests/icons/Facebooklogin.svg";
// import FacebookLogin from 'react-facebook-login';
const onFailure = (data) => {
  console.log("On Failure is ", data);
};

const clientId =
  "607917044082-bdmsv4h6t3enk7pgjigtn2b50kf75ogc.apps.googleusercontent.com";

const isSignedIn = true;

export function GoogleIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M31.6879 16.3687C31.6879 15.281 31.5997 14.1875 31.4115 13.1175H16.3198V19.2788H24.9621C24.6035 21.266 23.4512 23.0238 21.7639 24.1409V28.1386H26.9199C29.9476 25.352 31.6879 21.2366 31.6879 16.3687Z"
          fill="#4285F4"
        />
        <path
          d="M16.3205 32.0008C20.6357 32.0008 24.2749 30.5839 26.9264 28.1382L21.7704 24.1404C20.3359 25.1163 18.484 25.669 16.3263 25.669C12.1522 25.669 8.61294 22.8529 7.34306 19.0667H2.02246V23.188C4.73861 28.5909 10.2709 32.0008 16.3205 32.0008Z"
          fill="#34A853"
        />
        <path
          d="M7.33654 19.0668C6.66632 17.0796 6.66632 14.9279 7.33654 12.9407V8.81949H2.02183C-0.24751 13.3405 -0.24751 18.667 2.02183 23.188L7.33654 19.0668Z"
          fill="#FBBC04"
        />
        <path
          d="M16.3205 6.33288C18.6016 6.29761 20.8062 7.15596 22.4582 8.73156L27.0263 4.16349C24.1338 1.44734 20.2947 -0.0459547 16.3205 0.00107822C10.2709 0.00107822 4.73861 3.41096 2.02246 8.81974L7.33718 12.941C8.60119 9.14897 12.1463 6.33288 16.3205 6.33288Z"
          fill="#EA4335"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

const SocialLogin = () => {
  const location = useLocation();

  const isLoginScreen = Boolean(location.pathname === Routes.LOGIN);

  const { mutate: SignUpNewUser } = HandleSocialSignupAPI();

  const navigate = useNavigate();

  const onSuccess = (data) => {
    const payload = {
      is_social: true,
      name: data.profileObj.givenName + " " + data.profileObj.familyName,

      email: data.profileObj.email,
      password: undefined,
      is_admin: false,
    };

    SignUpNewUser(payload, {
      onSuccess: (response) => {
        navigate(Routes.REDIRECTOR);
        localStorage.setItem("isSocialAuth", true);
      },
    });
  };
  const responseFacebook = (response) => {
    const payload = {
      is_social: true,
      first_name: response.name.split(" ")[0],
      last_name: response.name.split(" ")[1],
      email: response.email,
      password: undefined,
      country_code: null,
      phone: null,
      is_terms_policy_accepted: true,
    };
    console.log(payload);

    SignUpNewUser(payload, {
      onSuccess: (response) => {
        navigate(Routes.REDIRECTOR);
        localStorage.setItem("isSocialAuth", true);
      },
    });
  };

  const onLogoutSuccessful = (data, data2) => {
    // SignUpNewUser({
    //   is_social: true,
    //   first_name: data.profileObj.givenName,
    //   last_name: data.profileObj.familyName,
    //   email: data.profileObj.email,
    //   password: undefined,
    //   country_code: null,
    //   phone: null,
    //   is_terms_policy_accepted: true,
    // });
  };

  const { signIn: googleSignIn, loaded } = useGoogleLogin({
    onSuccess,
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    isSignedIn,
    cookiePolicy: "single_host_origin",
    // fetchBasicProfile,
    onFailure,
    // scope: "",
  });

  useEffect(() => {
    // should we auto log-out user if they navigate to login page ?
    // googleSignOut();
  }, []);

  const handleGoogleLogin = () => {
    googleSignIn();
  };

  return (
    <Grid
      item
      xs={12}
      container
      spacing={4}
      className="flex flex-row justify-around"
    >
      <Grid item xs={6}>
        <ButtonBase className="social-login google" onClick={handleGoogleLogin}>
          <GoogleIcon />
        </ButtonBase>
      </Grid>
      <Grid item xs={6}>
        <FacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_APP_ID}
          callback={responseFacebook}
          fields="name,email,picture"
          scope="public_profile"
          render={(renderProps) => {
            return (
              <ButtonBase
                // {...renderProps}
                onClick={renderProps.onClick}
                className="social-login facebook"
              >
                <img src={FacebookLoginImg} width={35} />
              </ButtonBase>
            );
          }}
        />
      </Grid>
    </Grid>
  );
};

export default SocialLogin;
