import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, Typography } from "@mui/material";
import LabelledTextField from "components/LabelledTextField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import LabelledTextFieldWithAdornments from "components/LabelledTextFieldWithAdornments";
import {
  HandleForgotPasswordApi,
  HandleResetPasswordApi,
  HandleRestPasswordApi,
} from "./LoginQueries";

export default function ForgotPasswordEmail(props) {
  const {
    openModal,
    setOpenModal,
    forgotPassToken,
    children,
    resetPassword,
    setResetPassword,
    schema,
  } = props;
  const {
    register,
    handleSubmit,
    watch: loginFormCredentials,
    formState: { errors },
    clearErrors,
    getValues,
    reset,
    setFocus,
  } = useForm({ resolver: yupResolver(schema) });
  const [isEmailVeirfied, setIsEmailVerified] = useState(false);
  const { ref: refRegisterEmail, ...RegisterLoginEmail } = register("email");

  const handleClose = () => {
    clearErrors();
    reset();
    setOpenModal(false);
    forgotPasswordReset();
  };

  const {
    mutate: forgotPasswordUpdate,
    reset: forgotPasswordReset,
    status: forgotPasswordStatus,
    error: forgotPasswordError,
  } = HandleForgotPasswordApi();
  const resetPasswordApi = HandleResetPasswordApi();

  const handleResetPassword = (data) => {
    let tempData = {};

    tempData.email = data.email;
    forgotPasswordUpdate(tempData);
  };
  const handleResetPasswordError = (data) => {
    console.log();
    forgotPasswordReset();
  };
  useEffect(() => {
    if (forgotPasswordStatus === "success") {
      setIsEmailVerified(true);
    }
    return () => setIsEmailVerified(false);
  }, [forgotPasswordStatus]);

  return (
    <div className="px-12">
      <Dialog
        fullWidth={true}
        PaperProps={{
          sx: {
            overflow: "visible",
            padding: "20px 10px",
            minWidth: "300px",
            maxWidth: "480px",
          },
        }}
        open={openModal}
        onClose={handleClose}
      >
        <DialogTitle sx={{ mt: "-2px" }}>
          <Typography
            id="modal-modal-title"
            variant="h4"
            align="center"
            //     gutterBottom={true}
            component="h2"
            className="!font-semibold"
          >
            p Wave
          </Typography>
        </DialogTitle>
        {isEmailVeirfied ? (
          <div className="flex flex-col justify-center items-center">
            <Typography varaint="h3" className="!font-bold !my-4">
              You’ve got a mail
            </Typography>
            <Typography align="center" varaint="h5 " className="!w-[70%]">
              We have sent a verification email to{" "}
              <Typography varaint="h3" className="!font-bold ">
                {getValues("email")}
              </Typography>
            </Typography>
            <div className="!mx-12 w-[80%]">
              <Button fullWidth className="!my-8 " onClick={handleClose}>
                Check your email
              </Button>
            </div>
          </div>
        ) : (
          <form
            onSubmit={handleSubmit(
              handleResetPassword,
              handleResetPasswordError
            )}
          >
            <DialogContent>
              <LabelledTextField
                name="email"
                label="Email Id"
                type="email"
                inputRef={refRegisterEmail}
                {...RegisterLoginEmail}
                error={Boolean(errors.email) || Boolean(forgotPasswordError)}
                helperText={
                  (errors && errors.email && errors.email.message) ||
                  (forgotPasswordStatus === "error" &&
                    forgotPasswordError.message)
                }
                onChange={(e) => forgotPasswordReset()}
                autoComplete="email"
              />
            </DialogContent>
            <DialogActions className="!w-[90%] !mx-auto !mt-2">
              <Button
                fullWidth
                className="!text-lg"
                // onClick={handleForgetPassword}
                type="submit"
              >
                Verify
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>
    </div>
  );
}
