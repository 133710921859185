import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const StyledButtonPrimary = styled(Button)({});

export const PrimaryButton = (props) => {
  const { label, onClick, children, className, ...restProps } = props;

  return (
    <StyledButtonPrimary {...restProps} onClick={onClick} className={className}>
      {children}
    </StyledButtonPrimary>
  );
};
