import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, Grid, Typography } from "@mui/material";
import {
  AutoCompleteWithAdd,
  ColorBox,
  SingleMonthDialogCalendar,
} from "components";
import { HandleAddEventsAPI } from "containers/PWaveCalendar/PWaveCalendarQueries";
import moment from "moment";
import { CollectionsOutlined } from "@mui/icons-material";

export default function AddEventDialog(props) {
  const {
    openModal,
    setOpenModal,
    setEvents,
    addCategory,
    categoriesList,
    events,
  } = props;
  const { mutate: addEventApi } = HandleAddEventsAPI();
  const [value, setValue] = useState(null);
  const [showDate, setShowDate] = useState(false);
  const [showColorBox, setShowColorBox] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");
  const [options, setOptions] = useState([
    { title: "Meeting", color: "#5CC3B2" },
  ]);
  const [selectedDate, setSelectedDate] = useState([]);
  console.log(selectedDate);

  const handleClose = () => {
    setValue(null);
    setSelectedDate(null);
    setShowDate(false);
    setOpenModal(false);
  };

  const handleAddCategory = () => {
    const tempCategory = {};

    tempCategory.title = value.title;
    tempCategory.color_code = selectedColor;
    tempCategory.description = null;
    addCategory(tempCategory);

    handleClose();
  };
  const handleAddEvent = () => {
    const date = console.log(value, selectedDate);
    addEventApi({
      params: value.id,
      payload: {
        date: selectedDate,
      },
    });

    handleClose();
  };

  useEffect(() => {
    if (value?.title) {
      value?.color_code ? setShowDate(true) : setShowColorBox(true);
    } else {
      setShowDate(false);
      setShowColorBox(false);
    }
    setSelectedColor("");
  }, [value]);

  const SelectDateComponent = (
    <>
      <Grid item xs>
        <Typography
          id="modal-modal-title"
          align="left"
          className="!font-medium !text-[15px] !font-roboto"
          color="secondaryBlack.main"
        >
          Choose Dates
        </Typography>
      </Grid>
      <Grid item xs sx={{ pt: 2 }}>
        <SingleMonthDialogCalendar
          selectedCategory={value?.id}
          setEvents={setEvents}
          events={events}
          setSelectedDate={setSelectedDate}
          selectedDate={selectedDate}
        />
      </Grid>
    </>
  );
  const ColorBoxComponent = (
    <>
      <Grid item xs>
        <Typography
          id="modal-modal-title"
          align="left"
          className=" !text-[14px] !font-medium !font-roboto"
          color="grayText.main"
        >
          Add Color
        </Typography>
      </Grid>
      <Grid item xs sx={{ py: 2 }}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <ColorBox
              selectedColor={selectedColor}
              setSelectedColor={setSelectedColor}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  return (
    <Dialog open={openModal} maxWidth={600} onClose={handleClose}>
      <Grid container direction="column" sx={{ p: 2 }}>
        <Grid item xs>
          <Typography
            id="modal-modal-title"
            align="left"
            className="!font-semibold !text-[15px] !font-roboto"
            color="secondaryBlack.main"
          >
            {showColorBox
              ? "Add New Category"
              : "Choose any category or add it"}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography
            align="left"
            className="!font-medium !text-[14px] !font-roboto !mt-2"
            color={showColorBox ? "grayText.main" : "secondaryBlack.main"}
          >
            {showColorBox ? "Add Title" : "Type the word or category"}
          </Typography>
        </Grid>
        <Grid item xs sx={{ mt: 2, pb: 2 }}>
          <AutoCompleteWithAdd
            value={value}
            setValue={setValue}
            options={categoriesList}
          />
        </Grid>
        {showDate ? (
          <>{SelectDateComponent} </>
        ) : showColorBox ? (
          <>{ColorBoxComponent}</>
        ) : (
          ""
        )}
        {(selectedDate || selectedColor) && (
          <Grid item sx={{ pt: 3 }}>
            <Grid
              container
              alignItems="center"
              columnSpacing={4}
              justifyContent="center"
            >
              <Grid item>
                <Button
                  sx={{ minWidth: "100px" }}
                  onClick={showColorBox ? handleAddCategory : handleAddEvent}
                >
                  Save{" "}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={handleClose}
                  sx={{ minWidth: "100px" }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Dialog>
  );
}
