import { Grid, Typography, Button, FormHelperText } from "@mui/material";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import CardType from "assests/image/payment/CardType.svg";
import { useMemo, useRef, useState } from "react";
import { AddCardAPI, ChangeCardAPI } from "./PaymentQueries";
const InputElementWrapper = ({
  name,
  error,
  placeholder,
  inputRef,
  value,
  onChange,
}) => {
  return (
    <>
      <Grid item className="!mb-2 px-4 py-2 rounded-lg border-2 !mt-2">
        <input
          className="w-full h-full !border-none outline-none text-[15px] placeholder:text-[#aab7c4]"
          placeholder={placeholder}
          ref={inputRef}
          onChange={onChange}
          name={name}
          value={value}
          key={name}
        />
      </Grid>
      <FormHelperText
        error={Boolean(error)}
        margin="dense"
        variant="outlined"
        className="ml-0"
      >
        {error}
      </FormHelperText>
    </>
  );
};
const CardElementWrapper = ({ children }) => {
  return (
    <Grid item className="!mb-2 px-4 py-3 rounded-lg border-2 !mt-2">
      {children}
    </Grid>
  );
};
const PaymentForm = ({
  paymentIntentDetails,
  paymentDetailId,
  handleClose,
  refetchCardDetails,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const { mutate: addCard } = AddCardAPI();
  const { mutate: changeCard } = ChangeCardAPI();
  const cardNumberOption = useMemo(
    () => ({
      showIcon: true,
      style: {
        base: {
          fontSize: "15px",
          color: "#424770",
          letterSpacing: "0.025em",
          height: "100px",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },

        invalid: {
          color: "#9e2146",
        },
      },
      placeholder: "Card number",
    }),
    []
  );
  const cardExpiryOption = useMemo(
    () => ({
      style: {
        base: {
          fontSize: "15px",
          color: "#424770",
          letterSpacing: "0.025em",
          height: "100px",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },

        invalid: {
          color: "#9e2146",
        },
      },
      placeholder: "Expiration date (MM/YY)",
    }),
    []
  );
  const cardCvvOption = useMemo(
    () => ({
      style: {
        base: {
          fontSize: "15px",
          color: "#424770",
          letterSpacing: "0.025em",
          height: "100px",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },

        invalid: {
          color: "#9e2146",
        },
      },
      placeholder: "Security code (CVV)",
    }),
    []
  );
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    check: false,
  });
  const [formError, setFormError] = useState({
    firstName: "",
    lastName: "",
    check: "",
  });
  const handleFormData = (e) => {
    setFormData((formData) => {
      return { ...formData, [e.target.name]: e.target.value };
    });
    setFormError((formData) => {
      return { ...formData, [e.target.name]: "" };
    });
  };
  const handleCheckInput = () => {
    setFormData({ ...formData, check: !formData.check });
    setFormError({ ...formError, check: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
    });
    // console.log(paymentIntentDetails);
    // const payload = await stripe.confirmSetupIntent(
    //   paymentIntentDetails.data.client_secret,
    //   elements.getElement(CardNumberElement)
    // );
    // if (payload?.setupIntent?.payment_method) {
    //   addCard({
    //     source: payload.setupIntent.payment_method,
    //     is_primary: true,
    //   });
    // }
    console.log(payload);
    const postBody = {
      source: payload.paymentMethod.id,
      is_primary: true,
    };
    const changeCardBody = {
      id: paymentDetailId,
      body: {
        source: payload.paymentMethod.id,
      },
    };
    Boolean(paymentDetailId)
      ? changeCard(changeCardBody, {
          onSuccess: () => {
            //  refetchCardDetails();
            handleClose();
          },
        })
      : addCard(postBody);
  };

  return (
    <div
      className={` w-full py-4 rounded-lg px-4	${
        !paymentDetailId && "border border-grayborder"
      }`}
    >
      <form onSubmit={handleSubmit}>
        <Grid container direction="column">
          <Grid item>
            <Typography
              align="left"
              color="secondaryBlack.main"
              className="!my-4 !font-medium"
              variant="h5"
            >
              Set up your credit or debit card
            </Typography>
          </Grid>
          <Grid item className="!mb-4">
            <img className="!w-[150px]" src={CardType} alt="cardType" />
          </Grid>
          {/* <InputElementWrapper
            error={formError.firstName}
            name="firstName"
            inputRef={firstNameRef}
            placeholder="First Name"
            value={formData.firstName}
            onChange={handleFormData}
          />
          <InputElementWrapper
            error={formError.lastName}
            name="lastName"
            inputRef={lastNameRef}
            placeholder="Last Name"
            value={formData.lastName}
            onChange={handleFormData}
          /> */}
          <CardElementWrapper>
            <CardNumberElement options={cardNumberOption} />
          </CardElementWrapper>
          <CardElementWrapper>
            <CardExpiryElement options={cardExpiryOption} />
          </CardElementWrapper>
          <CardElementWrapper>
            <CardCvcElement options={cardCvvOption} />
          </CardElementWrapper>
        </Grid>
        <Grid container>
          <Grid item className="!w-11/12 !mx-auto !my-6">
            <Button fullWidth variant="contained" type="submit">
              Continue
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default PaymentForm;
