import React from "react";
import clock from "assests/image/mindmap/clock.svg";
import { db } from "firebaseConfig";
import { useList } from "react-firebase-hooks/database";
import { useEffect, useState } from "react";
import useSound from "use-sound";
import sounds from "assests/worksound.wav";
import { ref } from "firebase/database";
import { FetchUserDetailsAPI } from "containers/LoginPage/LoginQueries";
import Tomato from "assests/icons/Tomato.svg";
import { useMemo } from "react";
import { useInterval } from "hooks/useinterval";
import moment from "moment";

const Pomodorail = ({}) => {
  const { data: userDetails } = FetchUserDetailsAPI();

  const [pomodiralStatus, setPomodorialStatus] = useState({
    isActivated: false,
    isWorkTime: false,
    isRestTime: false,
    title: "",
    label: "",
  });
  const [workAndRestTime, setWorkAndRestTime] = useState({
    workTime: [],
    restTime: [],
  });
  const playAndStop = () => {
    if (pomodiralStatus?.label) {
      play();
      setTimeout(() => stop(), 1000 * 10);
    }
  };

  console.log(workAndRestTime);
  const [userId, setUserId] = useState(userDetails?.data?.user?.id);
  // const userId = useMemo(
  //   () => userDetails?.data?.user?.id || "",
  //   [userDetails?.data?.user?.id]
  // );
  const [play, { stop, isPlaying }] = useSound(sounds, {
    volume: 0.5,
    interrupt: true,
    duration: 5000,
  });
  console.log(isPlaying, "play");
  const [snapshots, loading, error] = useList(
    ref(db, `${userDetails?.data?.user?.id}/pomodorial`)
  );
  function addMinutes(date, minutes) {
    return new Date(date.getTime() + minutes * 60000);
  }

  const CheckPomoDorial = () => {
    let isActive = false;
    (snapshots ?? [])?.map((pomodorial, i) => {
      const data = pomodorial.val();
      if (isActive) return;

      if (data?.isActivated) {
        if (!pomodiralStatus.isActivated) {
          console.log(data, snapshots, "play");
          setTimeout(() => {
            play();
          }, 2000);

          console.log(isPlaying, "play", "called");
        }

        isActive = true;
        createTimeSots(
          new Date(`${data.taskDate} ${data?.startTime}`),
          new Date(`${data.taskDate} ${data?.endTime} `)
        );
        setPomodorialStatus({
          isActivated: data?.isActivated,
          label: data?.title,

          title: data?.title,
        });
      } else if (i + 1 === snapshots.length && !data?.isActivated) {
        stop();
        setPomodorialStatus({
          isActivated: false,
          label: "",
          isWorkTime: false,
          isRestTime: false,
          title: "",
        });
        setWorkAndRestTime({
          workTime: [],
          restTime: [],
        });
      }
    });
  };

  const createTimeSots = (start, end) => {
    let startTime = start;
    let endTime = end;
    let index = 0;

    while (endTime > startTime) {
      let newTime;
      if (index === 0) {
        newTime = addMinutes(
          startTime,
          userDetails?.data?.user?.work_duration * 1
        );
        startTime = newTime;
        setWorkAndRestTime((state) => ({
          ...state,
          workTime: [start],
        }));
        // newTime = addMinutes(
        //   startTime,
        //   userDetails?.data?.user?.rest_duration * 1
        // );
        setWorkAndRestTime((state) => ({
          ...state,
          restTime: [...state.restTime, newTime],
        }));
        index = index + 1;
      } else if (index % 2 === 0) {
        newTime = addMinutes(
          startTime,
          userDetails?.data?.user?.rest_duration * 1
        );
        console.log(newTime);
        setWorkAndRestTime((state) => ({
          ...state,
          workTime: [...state.workTime, newTime],
        }));
      } else {
        newTime = addMinutes(
          startTime,
          userDetails?.data?.user?.work_duration * 1
        );
        console.log(newTime);
        setWorkAndRestTime((state) => ({
          ...state,
          restTime: [...state.restTime, newTime],
        }));
      }
      index = index + 1;
      startTime = newTime;
    }
  };
  const checkIsActive = (newTaskAdded) => {
    if (workAndRestTime.workTime.length && workAndRestTime.restTime.length) {
      const currentDate = new Date();
      console.log(currentDate);
      const { workTime, restTime } = workAndRestTime;
      workTime.forEach((timeStamp, index) => {
        if (workTime[index] < currentDate && restTime[index] > currentDate) {
          if (!pomodiralStatus.isWorkTime && !newTaskAdded) {
            playAndStop();
          }
          setPomodorialStatus((state) => ({
            ...state,
            isWorkTime: true,
            isRestTime: false,
            label: `${pomodiralStatus?.title} - Work Time`,
          }));
        } else if (
          restTime[index] < currentDate &&
          workTime[index + 1] > currentDate
        ) {
          if (!pomodiralStatus.isRestTime && !newTaskAdded) {
            playAndStop();
          }
          setPomodorialStatus((state) => ({
            ...state,
            isWorkTime: false,
            label: `${pomodiralStatus?.title} - Rest Time`,
            isRestTime: true,
          }));
        }
      });
    }
  };
  const { intervalRef } = useInterval(() => checkIsActive(false), 1000 * 60);
  useEffect(() => {
    if (userDetails?.data?.user?.id) {
      setUserId(userDetails?.data?.user?.id);
      // stop();
      // alert("called");
    }
    return () => stop();
  }, [userDetails?.data?.user?.id]);
  useEffect(() => {
    !loading && CheckPomoDorial();
  }, [loading, snapshots]);
  useEffect(() => {
    if (workAndRestTime.workTime.length && workAndRestTime.restTime.length) {
      checkIsActive(true);
    }
    return () => stop();
  }, [workAndRestTime]);

  console.log(isPlaying, "isPlaying");
  return (
    <>
      {pomodiralStatus?.isActivated && (
        <div className="flex  justify-center items-center gap-3 absolute  top-[18px] right-[50%]  translate-x-[50%]  z-[999]">
          <img src={Tomato} alt="clock" />
          <p className="text-white">{pomodiralStatus?.label}</p>
        </div>
      )}
    </>
  );
};
export default Pomodorail;
