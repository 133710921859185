import React from "react";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import {
  CalendarNav,
  CalendarNext,
  CalendarPrev,
  Eventcalendar,
  getJson,
} from "@mobiscroll/react";
import "./SingleMonthBigCalendarStyles.css";

function SingleMonthBigCalendar({
  events,
  mainSelectedDate,
  setMainSelectedDate,
}) {
  const date = new Date(); // 2009-11-10

  const defaultMonth = mainSelectedDate.toLocaleString("default", {
    month: "long",
  });
  const [myEvents, setEvents] = React.useState([]);
  const [month, setMonth] = React.useState(defaultMonth);

  const handleMonthChange = (event, inst) => {
    const date = event.month; // 2009-11-10
    const localMonth = date.toLocaleString("default", { month: "long" });

    setMonth(localMonth);
  };
  const customWithNavButtons = () => {
    return (
      <div className="!flex !justify-between !w-full items-center px-4">
        <div className="my-custom-title text-white font-semibold text-2xl">
          {month}
        </div>
        <CalendarNav
          className="hideMonth !text-white !font-semibold"
          cssClass="year"
        />
      </div>
    );
  };

  const responsive = React.useMemo(() => {
    return {
      custom: {
        // Custom breakpoint
        breakpoint: 600,
        view: {
          calendar: {
            labels: false,
          },
        },
      },
    };
  }, []);
  console.log(events);
  const handleDateChange = (event, date) => {
    setMainSelectedDate({ date: event.date, type: "Day" });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="bigCalendar">
      <Eventcalendar
        theme="material"
        themeVariant="light"
        clickToCreate={false}
        dragToCreate={false}
        touchUi={false}
        dragToMove={false}
        dragToResize={false}
        actionableEvents={false}
        // onEventClick={onEventClick}
        className={""}
        exclusiveEndDates={true}
        dayNamesMin={[
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Satruday",
        ]}
        onPageLoading={function (event, inst) {}}
        responsive={responsive}
        cssClass="bigCalendar"
        renderHeader={customWithNavButtons}
        onPageChange={handleMonthChange}
       selectedDate={mainSelectedDate}
        onCellClick={(event, inst) => handleDateChange(event, inst)}
        colors={events}
      />
    </div>
  );
}

export default SingleMonthBigCalendar;
