import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { AppBar, Footer } from "components";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "routes";
import AppTheme from "theme/AppTheme";
import "./App.css";
import { ToastContainer } from "react-toastify";

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        cacheTime: 1000 * 60 * 7,
        enabled: true,
        staleTime: Infinity,
        retry: 1,
      },
    },
  });

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <Router>
          <ThemeProvider theme={AppTheme}>
            <ToastContainer />
            <CssBaseline />
            <>
              <AppBar />
              <div className="App-Content">
                <Routes />
              </div>
              <Footer />
            </>
          </ThemeProvider>
        </Router>
      </QueryClientProvider>
    </div>
  );
}

export default App;
