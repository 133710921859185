import React, { useEffect } from "react";

import Dialog from "@mui/material/Dialog";

import { Button, Grid, Typography } from "@mui/material";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { TimeSlotSelect } from "components";
import {
  ChangePomodoriallTimingAPI,
  GetPomodoriallTimingAPI,
} from "./EditProfileQueries";

const addWeeklyTaskSchema = yup.object().shape({
  playTime: yup.string().required("Play time is required"),
  restTime: yup.string().required("Rest Time is required"),
});
const timeSlot = [
  { label: "5  min", value: 5 },
  { label: "10 min", value: 10 },

  { label: "15 min", value: 15 },
  { label: "20 min", value: 20 },
  { label: "25 min", value: 25 },
  { label: "30 min", value: 30 },
];

export default function PomodorialTaskDialog(props) {
  const { openDialog, setOpenDialog } = props;
  const { mutate: changeTiming } = ChangePomodoriallTimingAPI();

  const handleClose = () => {
    setOpenDialog(false);

    reset();
  };
  console.log(props);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch: fomData,
    formState: { errors },
    setFocus,
    reset,
  } = useForm({ resolver: yupResolver(addWeeklyTaskSchema) });

  const { playTime, restTime } = fomData();

  const submitPomodorialTiming = (data) => {
    const payload = {
      work_duration: parseInt(data.playTime),
      rest_duration: parseInt(data.restTime),
    };
    changeTiming(payload);
    reset();
    handleClose();
  };
  useEffect(() => {
    if (
      props?.userDetails?.work_duration ||
      props?.userDetails?.rest_duration
    ) {
      setValue("playTime", props?.userDetails?.work_duration);
      setValue("restTime", props?.userDetails?.rest_duration);
    }
  }, [props.userDetails]);

  console.log(errors);

  return (
    <Dialog
      maxWidth={"xs"}
      open={openDialog}
      onClose={handleClose}
      PaperProps={{
        sx: {
          overflow: "visible",
        },
      }}
    >
      <form onSubmit={handleSubmit(submitPomodorialTiming)}>
        <Grid container sx={{ p: 0 }} direction="column" rowSpacing={1}>
          <Grid item className="!my-1">
            <Typography align="center" className="!text-[19px] !font-semibold">
              Change your pomodorial play and rest time
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            className="!mt-6"
          >
            <Grid item container xs={5} className="gap-3 items-center">
              <Grid item>
                <Typography variant="subtitle2">Work Time </Typography>
              </Grid>
              <Grid item>
                <TimeSlotSelect
                  options={timeSlot}
                  control={control}
                  value={playTime}
                  name="playTime"
                />
              </Grid>
            </Grid>
            <Grid item container xs={5} className="gap-3 items-center">
              <Grid item>
                <Typography variant="subtitle2">Rest Time </Typography>
              </Grid>
              <Grid item>
                <TimeSlotSelect
                  options={timeSlot}
                  control={control}
                  value={restTime}
                  name="restTime"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container justifyContent="center" className="!gap-12 my-2">
              <Grid item xs={3}>
                <Button
                  fullWidth
                  variant="outlined"
                  sx={{ fontSize: "15px" }}
                  onClick={handleClose}
                  className="!mt-8 !mx-auto"
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button
                  fullWidth
                  sx={{ fontSize: "15px" }}
                  type="submit"
                  className="!mt-8 !mx-auto"
                >
                  Confirm
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
}
