import { Grid } from "@mui/material";
import React from "react";
import "./styles.css";
import { CirclePicker } from "react-color";

const ColorBox = ({ selectedColor, setSelectedColor }) => {
  console.log(selectedColor);
  const colors = [
    "#f44336",
    "#e91e63",
    "#9c27b0",
    "#673ab7",
    "#3f51b5",
    "#2196f3",
    "#03a9f4",
    "#00bcd4",
    "#009688",
    "#4caf50",
    "#8bc34a",
    "#cddc39",
    "#ffeb3b",
    "#ffc107",
    "#ff9800",
    "#ff5722",
    "#795548",
    "#607d8b",
  ];
  const onChange = (color, event) => {
    setSelectedColor(color.hex);
  };
  return (
    <CirclePicker colors={colors} color={selectedColor} onChange={onChange} />
  );
};

export default ColorBox;
