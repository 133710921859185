import { Numbers } from "@mui/icons-material";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useAPI, useToast } from "services";

// Sign up

const HandleAddMindMap = (body) => {
  const method = "POST";
  const url = `api/mindmaps/add`;
  return useAPI({ method, url, body });
};

export const HandleAddMindMapAPI = () => {
  const responseToast = useToast();
  const queryClient = useQueryClient();
  /**
   * Please refer to "https://react-query.tanstack.com/reference/useMutation#_top"
   */
  return useMutation(HandleAddMindMap, {
    onSuccess: (response) => {
      responseToast.toastSuccess(
        response.description || "Mind Map  Added Successfully"
      );
      queryClient.invalidateQueries("GET_MINDMAP_LIST");
      // ^ redirect the user after sign up page
    },
    onError: (error) => {
      if (error && error.error) {
        responseToast.toastError(
          error.message || "Error while  adding Mind Map",
          error
        );
      }
    },
  });
};
const HandleEditMindMap = (body) => {
  const { params, payload } = body;
  console.log(payload, body);
  const method = "PATCH";
  const url = `api/mindmaps/${params}/update`;
  return useAPI({ method, url, body: payload });
};

export const HandleEditMindMapAPI = (payload) => {
  const queryClient = useQueryClient();
  const responseToast = useToast();

  return useMutation(HandleEditMindMap, {
    onSuccess: (response) => {
      responseToast.toastSuccess(
        response.description || "Mind Map  Edited Successfully"
      );
      queryClient.invalidateQueries("GET_MINDMAP_LIST");
      queryClient.invalidateQueries(`GET_SINGLE_MINDMAP`, payload);

      // ^ redirect the user after sign up page
    },
    onError: (error) => {
      if (error && error.error) {
        responseToast.toastError(
          error.message || "Error while  adding Mind Map",
          error
        );
      }
    },
  });
};
const HandleEditMindMapName = (body) => {
  const { params, payload } = body;
  console.log(payload, body);
  const method = "PATCH";
  const url = `api/mindmaps/${params}/update-name`;
  return useAPI({ method, url, body: payload });
};

export const HandleEditMindMapNameAPI = (payload) => {
  console.log(payload);
  const queryClient = useQueryClient();

  const responseToast = useToast();

  return useMutation(HandleEditMindMapName, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("GET_MINDMAP_LIST");
      responseToast.toastSuccess(
        response.description || "Mind Map name updated Successfully"
      );

      // ^ redirect the user after sign up page
    },
    onError: (error) => {
      if (error && error.error) {
        responseToast.toastError(
          error.message || "Error while  updating Mind Map name",
          error
        );
      }
    },
  });
};

// Login
const FetchMindMap = () => {
  const method = "GET";
  const url = `api/mindmaps`;
  return useAPI({ method, url });
};

export const FetchMindMapAPI = () => {
  return useQuery("GET_MINDMAP_LIST", FetchMindMap, {
    enabled: true,
  });
};
const FetchSingleMindMap = (body) => {
  console.log(body);
  const { params } = body;
  const method = "GET";
  const url = `api/mindmaps/${params}/show`;
  return useAPI({ method, url });
};

export const FetchSingleMindMapAPI = (payload) => {
  const { params } = payload;

  return useQuery(
    [`GET_SINGLE_MINDMAP`, params],
    () => FetchSingleMindMap(payload),
    {
      enabled: params !== "default",
      onSuccess: () => {},
    }
  );
};
const FetchAvailableTimeSlot = (body) => {
  console.log(body);
  const { params } = body;
  const method = "GET";
  const url = `api/tasks/get-task-time-for-date/${body}`;
  return useAPI({ method, url });
};

export const FetchAvailableTimeSlotAPI = (payload) => {
  return useQuery(
    [`GET_TASK_TIME_SLOT`, payload],
    () => FetchAvailableTimeSlot(payload),
    {
      enabled: true,
      onSuccess: () => {},
    }
  );
};
const VerifyWebViewToken = (body) => {
  console.log(body);
  //  const { params } = body;
  const method = "GET";
  const url = `api/mindmaps/verify/${body}`;
  return useAPI({ method, url });
};

export const VerifyWebViewTokenAPI = (payload) => {
  //  const { params } = payload;
  const navigate = useNavigate();

  return useQuery(
    [`Verify_Token`, payload],
    () => VerifyWebViewToken(payload),
    {
      enabled: Boolean(payload),
      onSuccess: (response) => {
        localStorage.clear();
        console.log(response);
        localStorage.setItem("login", JSON.stringify(true));
        localStorage.setItem("token", response.data.token);

        localStorage.setItem("refresh_token", response.data.refresh_token);
        navigate(`/mind-map/web-view/${response.data.mind_map_id}`);
      },
    }
  );
};
const AddPomodorialTask = (payload) => {
  console.log(payload);
  const { taskId, body } = payload;
  const method = "PUT";
  const url = `api/tasks/update-task/${taskId}`;
  return useAPI({ method, url, body });
};

export const AddPomodorialTaskAPI = (payload) => {
  console.log(payload);
  //  const { params } = payload;
  const navigate = useNavigate();

  return useMutation(AddPomodorialTask, {
    enabled: Boolean(payload),
    onSuccess: (response) => {},
  });
};
const EditTask = (payload) => {
  console.log(payload);
  const { taskId, body } = payload;
  const method = "PATCH";
  const url = `api/tasks/update-task/${taskId}`;
  return useAPI({ method, url, body });
};

export const EditTaskAPI = (payload) => {
  console.log(payload);
  //  const { params } = payload;
  const navigate = useNavigate();

  return useMutation(EditTask, {
    enabled: Boolean(payload),
    onSuccess: (response) => {},
  });
};
const HandleDeleteTask = (params) => {
  const method = "DELETE";
  const url = `api/tasks/delete-task/${params}`;
  return useAPI({ method, url });
};

export const HandleDeleteTaskAPI = () => {
  const responseToast = useToast();
  /**
   * Please refer to "https://react-query.tanstack.com/reference/useMutation#_top"
   */
  return useMutation(HandleDeleteTask, {});
};
