import * as React from "react";
import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import { Controller } from "react-hook-form";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    width: "100%",
    minWidth: "30px",
    borderRadius: 6,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #000",
    fontSize: 14,
    fontWeight: 600,
    padding: "5px 12px 5px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.

    "&:focus": {
      borderRadius: 6,
      borderColor: "none",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

export default function TimeSlotSelect({
  options,
  control,
  name,
  defaultValue,
}) {
  const [age, setAge] = React.useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <div>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <Select {...field} input={<BootstrapInput />}>
            {options?.map((val) => {
              return (
                <MenuItem defaultValue={defaultValue} value={val.value}>
                  {val.label}
                </MenuItem>
              );
            })}
          </Select>
        )}
      />
    </div>
  );
}
