import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Divider, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { HandleDeleteCategoriesAPI } from "containers/PWaveCalendar/PWaveCalendarQueries";

const filter = createFilterOptions();

export default function AutoCompleteWithAdd({ value, setValue, options }) {
  const { mutate : deleteCategory} = HandleDeleteCategoriesAPI()
  
  const handleDeleteCategory =( e,value) =>{
    e.preventDefault()
    e.stopPropagation();
    deleteCategory(value.id)
  
  }
  return (
    <Autocomplete
      sx={{
        display: "inline-block",
        width: "360px",
        padding: "0px !important",
        "& .MuiOutlinedInput-root": {
          width: "360px",
          padding: "2px !important",
          borderRadius: 1,
        },
        "& .MuiAutocomplete-input": {
          marginLeft: "28px",
        },
      }}
      value={value}
      onChange={(event, newValue) => {
        if (typeof newValue === "string") {
          setValue({
            title: newValue,
          });
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setValue({
            title: newValue.inputValue,
          });
        } else {
          setValue(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some(
          (option) => inputValue === option.title
        );
        if (inputValue !== "" && !isExisting) {
          filtered.push({
            inputValue,
            title: `Add ${inputValue}`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      handleHomeEndKeys
      options={options}
      blurOnSelect={true}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.title;
      }}
      renderOption={(props, option) => (
        <li {...props}>
          {option.color_code ? (
            <span
              style={{
                backgroundColor: option.color_code,
                width: 10,
                height: 10,
                borderRadius: "50%",
                marginRight: 10,
                marginLeft: 5,
              }}
            ></span>
          ) : (
            <>
              <AddCircleIcon sx={{ marginRight: 1 }} fontSize="small" />
            </>
          )}
          {option.color_code && <Divider />}
          {option.title}
     {option.color_code &&    <IconButton className='!ml-auto !text-[16px]' size='small'><CloseIcon  onClick={(e) =>handleDeleteCategory(e,option)} className='!ml-auto !text-[16px]'/> </IconButton>}
        </li>
      )}
      renderInput={(params) => {
        return (
          <div style={{ position: "relative" }}>
            <span
              style={{
                backgroundColor: value?.color_code,
                position: "absolute",
                width: 10,
                top: "17px",
                left: "12px",
                height: 10,
                borderRadius: "50%",
                marginRight: 10,
                marginLeft: 5,
              }}
            ></span>
            <TextField {...params} />
          </div>
        );
      }}
    />
  );
}

const top100Films = [{ title: "Meeting", color: "#5CC3B2" }];
