import { Button, Dialog } from "@mui/material";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import Routes from "routes/RoutesConstants";

const IntroVideo = ({ IntroVideoModal }) => {
  const navigate = useNavigate();

  return (
    <Dialog open={IntroVideoModal} maxWidth="xs">
      <div className="relative">
        <ReactPlayer
          height={300}
          width={450}
          url="https://www.youtube.com/watch?v=QWaJ4CudNa4"
        />
        <div className="absolute right-1 bottom-1">
          <Button
            onClick={() => navigate(Routes.FREETRAIL)}
            className="w-[80px] !p-0 !m-0 !bg-red-500 !border-red-500"
          >
            skip
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
export default IntroVideo;
