import * as React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Box from "@mui/material/Box";
import "./styles.css";
import { Grid } from "@mui/material";

export default function CalendarViewSwitchButton({
  handleModeChange,
  modeName,
}) {
  return (
    <Grid container justifyContent="center">
      <Grid
        item
        sx={{
          borderRadius: 28,
          background: "#FFFFFF",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.06)",
          alignItems: "center",
        }}
      >
        <button
          className={
            modeName === "Day" ? "activeButtonMode" : "nonActiveButtonMode"
          }
          onClick={handleModeChange}
          name="Day"
        >
          Day
        </button>
        <button
          className={
            modeName === "Week" ? "activeButtonMode" : "nonActiveButtonMode"
          }
          onClick={handleModeChange}
          name="Week"
        >
          Week
        </button>
        <button
          className={
            modeName === "Month" ? "activeButtonMode" : "nonActiveButtonMode"
          }
          onClick={handleModeChange}
          name="Month"
        >
          Month
        </button>
        <button
          className={
            modeName === "Year" ? "activeButtonMode" : "nonActiveButtonMode"
          }
          onClick={handleModeChange}
          name="Year"
        >
          Year
        </button>
      </Grid>
    </Grid>
  );
}
