import { PlusOutlined } from "@ant-design/icons";
import { Modal, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { useToast } from "services";
import { UploadImageQuery } from "containers/LoginPage/LoginQueries";
import "./ImageUploadFieldAntd.css";
const isEqual = require("lodash/isEqual");
const { Dragger } = Upload;
const initialState = {
  previewVisible: false,
  previewImage: "",
  previewTitle: "",
  fileList: [],
};
const uploadButton = (
  <div>
    <PlusOutlined />
    <div style={{ marginTop: 8 }}>Upload</div>
  </div>
);
const FileUploadComponent = (props) => {
  const {
    files,
    type,
    multiple,
    maxCount,
    maxSize,
    maxSizeDisplay,
    multipleMaxSize,
    handleFiles,
    source,
    label,
    required,
    defaultFiles,
    defaultImage,
    disabled,
    accept,
    image,
    setImage,
    ...restProps
  } = props;
  /**
   * source and handleFiles are mandatory
   * files is uploaded images, default values for fileList
   */
  const responseToast = useToast();
  const [previewImage, setPreviewImage] = useState(false);
  const [allFiles, setAllFiles] = useState(defaultFiles || []);
  const submittedFiles = allFiles;

  const updateFormValues = (data) => {
    if (checkShouldUpdateDefaultFile(defaultFiles)) {
      handleFiles(data);
    }
  };
  const checkShouldUpdateDefaultFile = (defaultFiles) => {
    const defaultFileLinks = defaultFiles.map((file) => file.image_url);
    const allFilesLinks = allFiles.map((file) => file.image_url);
    return !isEqual(defaultFileLinks, allFilesLinks);
  };
  useEffect(() => {
    if (
      defaultFiles &&
      defaultFiles.length > 0 &&
      checkShouldUpdateDefaultFile(defaultFiles)
    ) {
      setAllFiles(defaultFiles);
      submittedFiles.push(
        ...defaultFiles.map((file) => {
          return { ...file, thumbUrl: file.image_path };
        })
      );
    }
    if (defaultFiles && defaultFiles.length === 0 && allFiles.length !== 0) {
      setAllFiles([]);
    }
  }, [defaultFiles]);
  const [refresh, setRefresh] = useState(false);
  const updateUI = () => {
    setRefresh(!refresh);
  };
  const { mutateAsync: UploadImage } = UploadImageQuery();
  // const { mutate: deleteImage } = DeleteImageAPI();
  const handlePreviewImage = (file) => {
    setPreviewImage(file);
  };
  const handleClosePreview = () => {
    setPreviewImage(false);
  };
  const handleCustomRequest = ({ file }) => {
    if (file && maxSize && file.size > maxSize) {
      responseToast.toastError(
        `Could not upload image ${file.name}. It exceeds the size limit ${maxSizeDisplay}`
      );
      return false;
    }
    const fileIndex = submittedFiles.length;
    submittedFiles.push(file);

    setAllFiles([...allFiles, file]);

    const payload = {
      type,
      file: file,
    };
    UploadImage(payload).then((data) => {
      console.log(data);
      submittedFiles[fileIndex] = {
        ...submittedFiles[fileIndex],
        ...data.data,
        thumbUrl: data.data.image_path,
      };
      setAllFiles(submittedFiles);
      updateFormValues(submittedFiles);
      setTimeout(() => {
        updateUI();
      }, 500);
    });
  };
  console.log(allFiles, submittedFiles);
  const handleDeleteFile = (file) => {
    // delete API ?
    setImage(null);
  };
  const handleChange = ({ file, onSuccess }) => {
    const payload = {
      type,
      file: file,
    };
    UploadImage(payload).then((data) => {
      console.log(data);
      const uploadedImageDetails = {
        ...data.data,
        thumbUrl: data.data.imageStorageKey,
      };
      setImage(uploadedImageDetails);
      onSuccess();
      handleFiles(uploadedImageDetails);
    });
  };
  return (
    <div className="w-full h-full">
      {/* <InputLabel
        required={required}
        sx={{
          marginBottom: 2,
          color: "fieldLabel.main",
        }}
      >
        {label}
      </InputLabel> */}

      <div className="w-full h-full spanDiv">
        <Dragger
          customRequest={handleChange}
          maxCount={maxCount}
          multiple={multiple}
          disabled={disabled}
          accept={accept}
          {...restProps}
          listType="text"
        >
          <p className="ant-upload-text">Edit</p>
        </Dragger>
      </div>
    </div>
  );
};
export default FileUploadComponent;
FileUploadComponent.defaultProps = {
  files: [],
  multiple: false,
  maxCount: 1,
  label: "",
  required: false,
  accept: "image/jpeg,image/png",
};
