import {
  Email as EmailIcon,
  FacebookRounded as FacebookRoundedIcon,
  Instagram as InstagramIcon,
  LocationOn as LocationOnIcon,
  PhoneAndroid as PhoneAndroidIcon,
  Send as SendIcon,
  Twitter as TwitterIcon,
} from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";
import { LabelledTextField } from "components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ContactUsImage from "assests/image/contact-us/contactUs.svg";
import * as yup from "yup";
import "./style.css";
import { useHandleContactUsForm } from "./EditProfileQueries";

const ContactUsValidationSchema = yup.object().shape({
  name: yup
    .string()
    .required("Required")
    .max(50, "Name should be less than 50 characters"),

  email: yup.string().required("Required").email("Please enter a valid email"),
});

const ContactUs = () => {
  const {
    register,
    handleSubmit,
    watch: loginFormCredentials,
    formState: { errors },
    setFocus,
  } = useForm({ resolver: yupResolver(ContactUsValidationSchema) });

  const { mutate: addContactUsFormApi } = useHandleContactUsForm();

  const handleFormErrors = (errors) => {
    //
  };

  const { ref: refRegisterName, ...RegisterLoginName } = register("name");

  const { ref: refRegisterPincode, ...RegisterLoginPincode } =
    register("pincode");

  const { ref: refRegisterCountry, ...RegisterLoginCountry } =
    register("country");

  const { ref: refRegisterMessage, ...RegisterLoginMessage } =
    register("message");

  const { ref: refRegisterEmail, ...RegisterLoginEmail } = register("email");

  const SubmitContactUsDetails = (details) => {
    addContactUsFormApi({
      name: details.name,
      email: details.email,
      address: `${details.country},${details.pincode}`,
      message: details.message,
    });
  };
  return (
    <div>
      <div
        className="flex flex-col justify-center items-start px-24 contactUsImage"
        style={{
          minHeight: "300px",
        }}
      >
        <Typography
          variant="h2"
          className="!text-white !font-black"
          align="justify"
        >
          Contact Us
        </Typography>
        <Typography variant="h4" className="!text-white" align="justify">
          Get in touch with us
        </Typography>
      </div>
      <div className="bg-white my-10">
        <form onSubmit={handleSubmit(SubmitContactUsDetails, handleFormErrors)}>
          <Grid container spacing={0} className="px-24 ">
            <Grid
              item
              container
              xs={12}
              md={8}
              className="bg-white shadow-lg px-12 py-16 pt-12"
            >
              <Grid item xs={12} className="!mb-8">
                <Typography
                  variant="h6"
                  color="secondaryBlack.main"
                  className="!font-semibold"
                  align="justify"
                >
                  Send Message
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} className="pr-12">
                <LabelledTextField
                  label="Name"
                  type="text"
                  inputRef={refRegisterName}
                  {...RegisterLoginName}
                  error={Boolean(errors.name)}
                  helperText={errors && errors.name && errors.name.message}
                  autoComplete="first-name"
                  className="!mb-8"
                />

                <LabelledTextField
                  label="Zipcode"
                  type="text"
                  inputProps={{ maxLength: 5 }}
                  inputRef={refRegisterPincode}
                  {...RegisterLoginPincode}
                  error={Boolean(errors.pincode)}
                  autoComplete="pin"
                  className="!mb-8"
                />
              </Grid>
              <Grid item xs={12} sm={6} className="pr-12">
                <LabelledTextField
                  label="Email"
                  type="text"
                  inputRef={refRegisterEmail}
                  {...RegisterLoginEmail}
                  error={Boolean(errors.email)}
                  helperText={errors && errors.email && errors.email.message}
                  autoComplete="last-name"
                  className="!mb-8"
                />
                <LabelledTextField
                  label="Country"
                  type="text"
                  inputRef={refRegisterCountry}
                  {...RegisterLoginCountry}
                  error={Boolean(errors.country)}
                  autoComplete="country"
                  className="!mb-8"
                />
              </Grid>
              <Grid
                item
                xs={12}
                className="flex flex-row justify-center items-center"
              >
                <LabelledTextField
                  label="Message"
                  type="text"
                  inputRef={refRegisterMessage}
                  {...RegisterLoginMessage}
                  error={Boolean(errors.message)}
                  className="mr-4"
                />
                <IconButton type="submit">
                  <SendIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              className="bg-darkblue text-white px-12 py-16 pb-4 pt-12"
            >
              <Grid item xs={12} className="">
                <Typography variant="h6" align="justify" className="!mb-8">
                  Contact Information
                </Typography>
                <div className="flex flex-col justify-evenly">
                  <div className="flex flex-row items-center mb-7">
                    <LocationOnIcon className="mr-8" />
                    <div>
                      <Typography variant="body1" align="justify">
                        Trapped Anonymous Inc 5418 NW 79th Ave Doral, FL
                        33195-4416
                      </Typography>
                      <Typography variant="body1" align="justify">
                        United States
                      </Typography>
                    </div>
                  </div>
                  <div className="flex flex-row items-center mb-7">
                    <PhoneAndroidIcon className="mr-8" />
                    <div>
                      <Typography variant="body1" align="justify">
                        967-248-0421
                      </Typography>
                    </div>
                  </div>
                  <div className="flex flex-row items-center mb-10">
                    <EmailIcon className="mr-8" />
                    <div>
                      <a href="mailto:Gracie.Barrows@yahoo.com">
                        <Typography
                          variant="body1"
                          align="justify"
                          className="cursor-pointer"
                        >
                          Gracie.Barrows@yahoo.com
                        </Typography>
                      </a>
                    </div>
                  </div>
                  <div className="flex flex-row justify-around">
                    <FacebookRoundedIcon
                      fontSize="large"
                      className="cursor-pointer"
                    />
                    <TwitterIcon fontSize="large" className="cursor-pointer" />
                    <InstagramIcon
                      fontSize="large"
                      className="cursor-pointer"
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
