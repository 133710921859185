import React, { useEffect, useState } from "react";

import Dialog from "@mui/material/Dialog";

import { Button, Grid, Typography } from "@mui/material";

import { ColorBox, TextFieldWithoutLabel } from "components";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { yupResolver } from "@hookform/resolvers/yup";
import { HandleAddNotesAPI } from ".././NotesQueries";

const addNotesSchema = yup.object().shape({
  title: yup.string().required("Title is a required field"),
  description: yup.string(),
});

export default function AddNotesDialog(props) {
  const { openDialog, setOpenDialog, viewData, setViewData } = props;
  const { mutate: addNotes } = HandleAddNotesAPI();

  const handleClose = () => {
    setOpenDialog(false);

    reset();
    setTimeout(() => {
      setViewData(null);
    }, 500);
  };

  const {
    register,
    handleSubmit,
    setValue,
    watch: fomData,
    formState: { errors },
    setFocus,
    reset,
  } = useForm({ resolver: yupResolver(addNotesSchema) });
  const subtmitNotesForm = (data) => {
    console.log(data);
    addNotes(data);
    handleClose();
    reset();
  };

  const { ref: refTitle, ...titleReg } = register("title");
  const { ref: refDescription, ...descriptionRef } = register("description");
  console.log(errors);
  useEffect(() => {
    if (Boolean(viewData)) {
      setValue("title", viewData.title);
      setValue("description", viewData.description);
    }
  }, [viewData]);
  return (
    <Dialog
      maxWidth={"xs"}
      open={openDialog}
      onClose={handleClose}
      PaperProps={{
        sx: {
          overflow: "visible",
        },
      }}
    >
      <div
        onClick={handleClose}
        className="p-1 rounded-full -mt-10 shadow-lg w-8 h-8 flex justify-center items-center cursor-pointer z-50 mx-auto bg-white"
      >
        <HighlightOffIcon />
      </div>
      <form onSubmit={handleSubmit(subtmitNotesForm)}>
        <Grid container sx={{ p: 0 }} direction="column" rowSpacing={1}>
          <Grid item>
            <Typography
              className="!text-[18px] !font-roboto"
              variant="subtitle2"
            >
              Title
            </Typography>
          </Grid>
          <Grid item>
            <TextFieldWithoutLabel
              size="small"
              autoFocus
              inputProps={{ maxLength: 30 }}
              error={Boolean(errors.title)}
              helperText={errors && errors.title && errors.title.message}
              disabled={Boolean(viewData)}
              inputRef={refTitle}
              {...titleReg}
            />
          </Grid>

          <Grid item>
            <Typography>Description</Typography>
          </Grid>
          <Grid item>
            <TextFieldWithoutLabel
              size="small"
              multiLine
              disabled={Boolean(viewData)}
              inputProps={{ maxLength: 120 }}
              name="description"
              rows={3}
              inputRef={refDescription}
              {...descriptionRef}
            />
          </Grid>
        </Grid>
        <Grid item>
          {Boolean(viewData) || (
            <Grid container justifyContent="center" className="!gap-12">
              <Grid item xs={3}>
                <Button
                  fullWidth
                  sx={{ fontSize: "15px" }}
                  type="submit"
                  className="!mt-8 !mx-auto"
                >
                  Confirm
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button
                  fullWidth
                  variant="outlined"
                  sx={{ fontSize: "15px" }}
                  onClick={handleClose}
                  className="!mt-8 !mx-auto"
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </form>
    </Dialog>
  );
}
