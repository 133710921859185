import React, { useEffect, useState } from "react";

import Dialog from "@mui/material/Dialog";

import { Button, Grid, Typography } from "@mui/material";

import { ColorBox, TextFieldWithoutLabel } from "components";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import {
  HandleAddMonthlyGoalsAPI,
  HandleEditMonthlyGoalsAPI,
} from "containers/PWaveCalendar/PWaveCalendarQueries";

const addNotesSchema = yup.object().shape({
  description: yup.string().required("Description is required"),
});

export default function AddMonthlyNotesDialog(props) {
  const {
    openDialog,
    setOpenDialog,
    editData,
    setEditData,
    mainSelectedDate,
    month_start_date,
    month_end_date,
  } = props;
  const { mutate: addMonthlyGoals } = HandleAddMonthlyGoalsAPI();
  const { mutate: editMonthlyGoals } = HandleEditMonthlyGoalsAPI();
  const year = moment(mainSelectedDate).format("YYYY");
  const month = moment(mainSelectedDate).format("MMMM");

  const handleClose = () => {
    setOpenDialog(false);

    reset();
    setTimeout(() => {
      setEditData(null);
    }, 500);
  };

  const {
    register,
    handleSubmit,
    setValue,
    watch: fomData,
    formState: { errors },
    setFocus,
    reset,
  } = useForm({ resolver: yupResolver(addNotesSchema) });
  const subtmitNotesForm = (data) => {
    const payload = {
      title: data.description,
      year,
      month,
      month_start_date,
      month_end_date,
    };
    console.log(data);
    Boolean(editData)
      ? editMonthlyGoals({ params: editData.id, body: payload })
      : addMonthlyGoals(payload);

    handleClose();
    reset();
  };

  const { ref: refDescription, ...descriptionRef } = register("description");
  console.log(errors);
  useEffect(() => {
    if (Boolean(editData)) {
      setValue("description", editData.goal_title);
    }
  }, [editData]);
  return (
    <Dialog
      maxWidth={"xs"}
      open={openDialog}
      onClose={handleClose}
      PaperProps={{
        sx: {
          overflow: "visible",
        },
      }}
    >
      <div
        onClick={handleClose}
        className="p-1 rounded-full -mt-10 shadow-lg w-8 h-8 flex justify-center items-center cursor-pointer z-50 mx-auto bg-white"
      >
        <HighlightOffIcon />
      </div>
      <form onSubmit={handleSubmit(subtmitNotesForm)}>
        <Grid container sx={{ p: 0 }} direction="column" rowSpacing={1}>
          <Grid item className="!my-2">
            <Typography className="!text-[16px] !font-semibold">
              {" "}
              Add your monthly goal description
            </Typography>
          </Grid>
          <Grid item>
            <TextFieldWithoutLabel
              size="small"
              multiLine
              autoFocus
              inputProps={{ maxLength: 120 }}
              name="description"
              rows={3}
              inputRef={refDescription}
              {...descriptionRef}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justifyContent="center" className="!gap-12">
            <Grid item xs={3}>
              <Button
                fullWidth
                sx={{ fontSize: "15px" }}
                type="submit"
                className="!mt-8 !mx-auto"
              >
                Confirm
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                fullWidth
                variant="outlined"
                sx={{ fontSize: "15px" }}
                onClick={handleClose}
                className="!mt-8 !mx-auto"
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
}
