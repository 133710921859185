import HomePage from "containers/HomePage";
import LoginPage from "containers/LoginPage";
import ContactUs from "containers/HomePage/ContactUs";
import { lazy, Suspense, useEffect, useMemo } from "react";

import {
  BrowserRouter as Router,
  Navigate,
  Outlet,
  Redirect,
  Route,
  Routes,
} from "react-router-dom";
import RoutesConstants from "./RoutesConstants";
import { Backdrop, CircularProgress } from "@mui/material";
import { useIsFetching, useIsMutating } from "react-query";
import { FetchUserDetailsAPI } from "containers/LoginPage/LoginQueries";
import { PWaveChart } from "components/PWaveChart";
import NoteBook from "containers/Notebook";

import { useToast } from "services";
import PrivacyPolicyPage from "components/PrivacyPolicyPage";
import { UserProfile } from "containers/HomePage/UserProfile";
const SetupCalendar = lazy(() => import("containers/Calendar/Setup"));

const PreviewCalendar = lazy(() =>
  import("containers/Calendar/PreviewCalendar")
);
const PWaveChartView = lazy(() => import("containers/PWaveCalendar"));
const MindMapWebView = lazy(() => import("containers/MindMap/MindMapWebView"));
const MindMapPreview = lazy(() => import("containers/MindMap"));
const MindMap = lazy(() => import("containers/MindMap/MindMap"));
const VideoPage = lazy(() => import("containers/Video"));
const VideoPlayer = lazy(() => import("containers/Video/VideoPlayer"));
const ProfilePage = lazy(() => import("containers/HomePage/EditProfile"));

const FreeTrailPage = lazy(() => import("containers/FreeTrail"));

const AppRoutes = () => {
  const runningAPICount = useIsFetching();
  const mutatingAPICount = useIsMutating();
  const responseToast = useToast();

  console.log(runningAPICount, mutatingAPICount);

  return (
    <>
      <Suspense fallback={<Backdrop open={true} />}>
        {" "}
        <Routes>
          {/* <Redirect exact from="/" to="/" /> */}
          <Route path={RoutesConstants.LOGIN} element={<LoginPage />} />
          <Route path={RoutesConstants.SIGNUP} element={<LoginPage />} />
          <Route path={RoutesConstants.CONTACT_US} element={<ContactUs />} />
          <Route path={RoutesConstants.PROFILE} element={<UserProfile />} />
          <Route
            path={`${RoutesConstants.MINDMAP}/web-view/:m`}
            element={<MindMap />}
          />
          <Route
            path={`${RoutesConstants.MINDMAPWEBVIEW}`}
            element={<MindMapWebView />}
          />
          <Route element={<ProtectedRoutes />}>
            <Route element={<MembershipRoutes />}>
              <Route
                path={RoutesConstants.CALENDAR_SETUP}
                element={<SetupCalendar />}
              />

              <Route
                path={RoutesConstants.CALENDAR_PREVIEW}
                element={<PreviewCalendar />}
              />
              <Route
                path={RoutesConstants.PWAVECALENDAR}
                element={<PWaveChartView />}
              />
              <Route
                path={RoutesConstants.MINDMAP}
                element={<MindMapPreview />}
              />
              <Route
                path={`${RoutesConstants.MINDMAP}/:m`}
                element={<MindMap />}
              />

              <Route path={RoutesConstants.VIDEO} element={<VideoPage />} />
              <Route
                path={`${RoutesConstants.VIDEO}/:type/:v`}
                element={<VideoPlayer />}
              />
              <Route path={`${RoutesConstants.NOTES}`} element={<NoteBook />} />
            </Route>
            <Route
              path={`${RoutesConstants.FREETRAIL}`}
              element={<FreeTrailPage />}
            />
            {/* <Route path={RoutesConstants.PROFILE} element={<ProfilePage />} /> */}
          </Route>

          {/** DON'T CHANGE THE POSITION OF REDIRECTOR ROUTE - SHOULD BE LAST */}
          <Route
            path={RoutesConstants.REDIRECTOR}
            exact
            element={<HomePage />}
          />
          <Route
            path={RoutesConstants.PRIVACY_POLICY}
            exact
            element={<PrivacyPolicyPage />}
          />
        </Routes>
        <Backdrop
          open={Boolean(runningAPICount || mutatingAPICount)}
          sx={{
            zIndex: (theme) => theme.zIndex.modal + 100,
            backgroundColor: "rgba(0,0,0,0.3)",
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Suspense>
    </>
  );
};
const ProtectedRoutes = () => {
  const isLoggedIn = localStorage.getItem("token");
  return isLoggedIn ? (
    <Outlet />
  ) : (
    <Navigate to={RoutesConstants.LOGIN} replace />
  );
};
const MembershipRoutes = () => {
  const userDetail = JSON.parse(localStorage.getItem("user_details"));
  console.log(userDetail, "test");
  const is_membership_active = useMemo(() => {
    return userDetail?.is_membership_active ?? false;
  }, []);
  return is_membership_active ? (
    <Outlet />
  ) : (
    <Navigate to={RoutesConstants.REDIRECTOR} replace />
  );
};

export default AppRoutes;
