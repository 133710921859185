import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAPI, useToast } from "services";

// Sign up

const HandleAddCategories = (body) => {
  const method = "POST";
  const url = `api/categories/add-category`;
  return useAPI({ method, url, body });
};

export const HandleAddCategoriesAPI = () => {
  const responseToast = useToast();
  /**
   * Please refer to "https://react-query.tanstack.com/reference/useMutation#_top"
   */
  return useMutation(HandleAddCategories, {
    onSuccess: (response) => {
      responseToast.toastSuccess(
        response.description || "Category Added Successfully"
      );

      // ^ redirect the user after sign up page
    },
    onError: (error) => {
      if (error && error.error) {
        responseToast.toastError(
          error.error_messages || "Error while  adding Category",
          error
        );
      }
    },
  });
};
const HandleDeleteCategories = (params) => {
  const method = "DELETE";
  const url = `api/categories/delete-category/${params}`;
  return useAPI({ method, url });
};

export const HandleDeleteCategoriesAPI = () => {
  const responseToast = useToast();
  const queryClient = useQueryClient()
  /**
   * Please refer to "https://react-query.tanstack.com/reference/useMutation#_top"
   */
  return useMutation(HandleDeleteCategories, {
  
    onSuccess: (response) => {
      queryClient.invalidateQueries("FETCH_ALL_EVENTS");
      queryClient.invalidateQueries("GET_CATEGORIES_LIST");
      responseToast.toastSuccess(
        response.description || "Category Deleted Successfully"
      );

      // ^ redirect the user after sign up page
    },
    onError: (error) => {
      if (error && error.error) {
     
        responseToast.toastError(
          error.error_messages || "Error while  adding Category",
          error
        );
      }
    },
  });
};
const HandleAddEvents = (body) => {
  const { params, payload } = body;
  const method = "POST";
  const url = `api/categories/add-calendar/${params}`;
  return useAPI({ method, url, body: payload });
};

export const HandleAddEventsAPI = () => {
  const responseToast = useToast();
  const queryClient = useQueryClient();
  /**
   * Please refer to "https://react-query.tanstack.com/reference/useMutation#_top"
   */
  return useMutation(HandleAddEvents, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("FETCH_ALL_EVENTS");
      responseToast.toastSuccess(
        response.description || "Category Added Successfully"
      );

      // ^ redirect the user after sign up page
    },
    onError: (error) => {
      if (error && error.error) {
        responseToast.toastError(
          error.error_messages || "Error while  adding Category",
          error
        );
      }
    },
  });
};

// Login
const FetchEvents = () => {
  const method = "GET";
  const url = `api/categories/get-calendar-events`;
  return useAPI({ method, url });
};

export const FetchEventsAPI = () => {
  return useQuery("FETCH_ALL_EVENTS", FetchEvents, {
    enabled: true,
  });
};
const FetchCategories = () => {
  const method = "GET";
  const url = `api/categories/my-categories`;
  return useAPI({ method, url });
};

export const FetchCategoriesAPI = () => {
  return useQuery("GET_CATEGORIES_LIST", FetchCategories, {
    enabled: true,
  });
};
const FetchTaskListByWeek = (params) => {
  const { week_end_day, week_start_day } = params;
  const method = "GET";
  let url = `api/tasks/get-tasks/by-week?`;
  if (week_end_day) url = `${url}week_start_date=${week_start_day}&`;
  if (week_end_day) url = `${url}week_end_date=${week_end_day}`;
  return useAPI({ method, url });
};

export const FetchTaskListByWeekAPI = (payload) => {
  return useQuery(
    ["GET_TASK_LIST_BY_WEEK", payload],
    () => FetchTaskListByWeek(payload),
    {
      enabled: true,
    }
  );
};
const FetchTaskList = (params) => {
  const method = "GET";
  const url = `api/tasks/get-tasks/${params}`;
  return useAPI({ method, url });
};

export const FetchTaskListAPI = (payload) => {
  return useQuery(["GET_TASK_LIST", payload], () => FetchTaskList(payload), {
    enabled: true,
  });
};
const FetchChartData = () => {
  const method = "GET";
  const url = `api/preferences/get-preference-graph-data`;
  return useAPI({ method, url });
};

export const FetchChartDataAPI = () => {
  return useQuery("GET_CHART_DATA_LIST", FetchChartData, {
    enabled: true,
  });
};
const FetchChartDataByDate = (payload) => {
  const method = "GET";
  const url = `api/preferences/get-preference-graph-data-by-date/${payload}`;
  return useAPI({ method, url });
};

export const FetchChartDataByDateAPI = (payload) => {
  return useQuery(
    ["GET_CHART_DATA_LIST_BY_DATE", payload],
    () => FetchChartDataByDate(payload),
    {
      enabled: true,
    }
  );
};
const HandleAddTask = (body) => {
  const method = "POST";
  const url = `api/tasks/add-task`;
  return useAPI({ method, url, body });
};

export const HandleAddTaskAPI = () => {
  const responseToast = useToast();
  const queryClient = useQueryClient();
  /**
   * Please refer to "https://react-query.tanstack.com/reference/useMutation#_top"
   */
  return useMutation(HandleAddTask, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("GET_TASK_LIST");
      responseToast.toastSuccess(
        response.description || "Task Added Successfully"
      );
    },
    onError: (error) => {
      if (error && error.error) {
        responseToast.toastError(
          error.error_messages || "Error while  adding Task",
          error
        );
      }
    },
  });
};
const HandleSwapTask = (body) => {
  const method = "PUT";
  const url = `api/tasks/swap-tasks`;
  return useAPI({ method, url, body });
};

export const HandleSwapTaskAPI = () => {
  const responseToast = useToast();
  const queryClient = useQueryClient();
  /**
   * Please refer to "https://react-query.tanstack.com/reference/useMutation#_top"
   */
  return useMutation(HandleSwapTask, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(["GET_TASK_LIST"]);
      responseToast.toastSuccess(
        response.description || "Task Swapped Successfully"
      );
    },
    onError: (error) => {
      if (error && error.error) {
        responseToast.toastError(
          error.error_messages || "Error while  swapping Task",
          error
        );
      }
    },
  });
};
const HandleAddWeeklyGoals = (body) => {
  const method = "POST";
  const url = `api/weekly-goals`;
  return useAPI({ method, url, body });
};

export const HandleAddWeeklyGoalsAPI = () => {
  const responseToast = useToast();
  const queryClient = useQueryClient();

  /**
   * Please refer to "https://react-query.tanstack.com/reference/useMutation#_top"
   */
  return useMutation(HandleAddWeeklyGoals, {
    onSuccess: (response) => {
      responseToast.toastSuccess(
        response.description || "Task Added Successfully"
      );
      queryClient.invalidateQueries("GET_WEEKLY_TASK");
    },
    onError: (error) => {
      if (error && error.error) {
        responseToast.toastError(
          error.error_messages || "Error while  adding Task",
          error
        );
      }
    },
  });
};
const HandleEditWeeklyGoals = (payload) => {
  const { params, body } = payload;
  const method = "PATCH";
  const url = `api/weekly-goals/${params}`;
  return useAPI({ method, url, body });
};

export const HandleEditWeeklyGoalsAPI = (payload) => {
  const responseToast = useToast();
  const queryClient = useQueryClient();
  /**
   * Please refer to "https://react-query.tanstack.com/reference/useMutation#_top"
   */
  return useMutation(HandleEditWeeklyGoals, {
    onSuccess: (response) => {
      responseToast.toastSuccess(
        response.description || "Goal Edited Successfully"
      );
      queryClient.invalidateQueries("GET_WEEKLY_TASK");
    },
    onError: (error) => {
      if (error && error.error) {
        responseToast.toastError(
          error.error || "Error while  adding Task",
          error
        );
      }
    },
  });
};
const HandleDeleteWeeklyGoals = (params) => {
  const method = "DELETE";
  const url = `api/weekly-goals/${params}`;
  return useAPI({ method, url });
};

export const HandleDeleteWeeklyGoalsAPI = (payload) => {
  const responseToast = useToast();
  const queryClient = useQueryClient();
  /**
   * Please refer to "https://react-query.tanstack.com/reference/useMutation#_top"
   */
  return useMutation(HandleDeleteWeeklyGoals, {
    onSuccess: (response) => {
      responseToast.toastSuccess(
        response.description || "Goal Deleted Successfully"
      );
      queryClient.invalidateQueries("GET_WEEKLY_TASK");
    },
    onError: (error) => {
      if (error && error.error) {
        responseToast.toastError(
          error.error || "Error while  adding Task",
          error
        );
      }
    },
  });
};
const FetchWeeklyTask = (params) => {
  const { week_end_day, week_start_day } = params;
  const method = "GET";
  let url = `api/weekly-goals/user/by-week?`;
  if (week_end_day) url = `${url}week_start_date=${week_start_day}&`;
  if (week_end_day) url = `${url}week_end_date=${week_end_day}`;
  return useAPI({ method, url });
};

export const FetchWeeklyTaskAPI = (payload) => {
  return useQuery(
    ["GET_WEEKLY_TASK", payload],
    () => FetchWeeklyTask(payload),
    {
      enabled: true,
    }
  );
};
const HandleAddMonthlyGoals = (body) => {
  const method = "POST";
  const url = `api/monthly-goals`;
  return useAPI({ method, url, body });
};

export const HandleAddMonthlyGoalsAPI = () => {
  const responseToast = useToast();
  const queryClient = useQueryClient();
  /**
   * Please refer to "https://react-query.tanstack.com/reference/useMutation#_top"
   */
  return useMutation(HandleAddMonthlyGoals, {
    onSuccess: (response) => {
      responseToast.toastSuccess(
        response.description || "Monthly Goal Added Successfully"
      );
      queryClient.invalidateQueries("GET_MONTHLY_GOALS");
    },
    onError: (error) => {
      if (error && error.error) {
        responseToast.toastError(
          error.error_messages || "Error while  adding Task",
          error
        );
      }
    },
  });
};
const HandleEditMonthlyGoals = (payload) => {
  console.log(payload);
  const { params, body } = payload;
  const method = "PATCH";
  const url = `api/monthly-goals/${params}`;
  return useAPI({ method, url, body });
};

export const HandleEditMonthlyGoalsAPI = (payload) => {
  const responseToast = useToast();
  const queryClient = useQueryClient();
  /**
   * Please refer to "https://react-query.tanstack.com/reference/useMutation#_top"
   */
  return useMutation(HandleEditMonthlyGoals, {
    onSuccess: (response) => {
      responseToast.toastSuccess(
        response.description || "Goal Edited Successfully"
      );
      queryClient.invalidateQueries("GET_MONTHLY_GOALS");
    },
    onError: (error) => {
      if (error && error.error) {
        responseToast.toastError(
          error.error || "Error while  adding Task",
          error
        );
      }
    },
  });
};
const HandleDeleteMonthlyGoals = (params) => {
  const method = "DELETE";
  const url = `api/monthly-goals/${params}`;
  return useAPI({ method, url });
};

export const HandleDeleteMonthlyGoalsAPI = (payload) => {
  const responseToast = useToast();
  const queryClient = useQueryClient();
  /**
   * Please refer to "https://react-query.tanstack.com/reference/useMutation#_top"
   */
  return useMutation(HandleDeleteMonthlyGoals, {
    onSuccess: (response) => {
      responseToast.toastSuccess(
        response.description || "Goal Deleted Successfully"
      );
      queryClient.invalidateQueries("GET_MONTHLY_GOALS");
    },
    onError: (error) => {
      if (error && error.error) {
        responseToast.toastError(
          error.error || "Error while  adding Task",
          error
        );
      }
    },
  });
};
const FetchMonthlyTask = (params) => {
  const { month_end_date, month_start_date } = params;
  const method = "GET";
  let url = `api/monthly-goals/user/by-month?`;
  if (month_end_date) url = `${url}month_start_date=${month_start_date}&`;
  if (month_end_date) url = `${url}month_end_date=${month_end_date}`;
  return useAPI({ method, url });
};

export const FetchMonthlyTaskAPI = (payload) => {
  return useQuery(
    ["GET_MONTHLY_GOALS", payload],
    () => FetchMonthlyTask(payload),
    {
      enabled: true,
    }
  );
};
