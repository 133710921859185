import {
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import React, { forwardRef, useState } from "react";

const PREFIX = "LabelledTextFieldBootStrap";

const classes = {
  inputFormRoot: `${PREFIX}-inputFormRoot`,
  inputLabelRoot: `MuiInputLabel-root`,
  inputLabelFormControl: `${PREFIX}-inputLabelFormControl`,
  outlinedInput: `${PREFIX}-outlinedInput`,
  notchedOutline: `${PREFIX}-notchedOutline`,
  // MuiInputRoot: `MuiInput-root`,
};

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  [`& .${classes.inputFormRoot}`]: {
    display: "flex",
  },

  [`& .${classes.inputLabelRoot}`]: {
    fontWeight: "400",
    fontSize: "16px",
    color: "#545454 !important",
    lineHeight: "1.3125rem",
  },
  // [`& .${classes.MuiInputRoot}`]: {
  //   borderBottom: "1px solid #808080",
  // },

  [`& .${classes.inputLabelFormControl}`]: {
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.notchedOutline}`]: {
    border: "1px solid #808080",
    width: "100%",
  },
  [`& .${classes.outlinedTextField}`]: {
    background: "#FFFFFF",
    order: "1px solid #808080",
    borderRadius: 10,
    fontSize: "14px",
    fontWeight: "400",
    color: "#464E5F",
    borderRadius: "6px",
    width: "100%",
    paddingRight: "0px",
  },
  [`& .${classes.outlinedInput}`]: {
    // background: "#F3F6F9",
    fontSize: "14px",
    fontWeight: "400",
    color: "#464E5F",
    borderRadius: "6px",
    paddingRight: "0px",
  },
}));

/**
 * Bootstrap type input textfield
 * takes full width
 *
 * for hide / show password type input : use "LabelledTextFieldWithAdornments" component
 */

const LabelledTextFieldBootStrap = forwardRef((props, ref) => {
  const {
    label,
    variant,
    type,
    error,
    helperText,
    className,
    inputRef,
    required,
    fullWidth,
    size,
    name,
    disabled,
    capitalize,
    ...restOfTextFieldProps
  } = props;

  const [showPassword, setShowPassword] = useState(false);

  return (
    <StyledFormControl
      classes={{
        root: classes.inputFormRoot,
      }}
      className={`${className} ${fullWidth ? "w-full" : ""} mui-textfield`}
    >
      <TextField
        type={type === "password" ? (showPassword ? "text" : "password") : type}
        className={`${classes.outlinedInput} ${capitalize ? "capitalize" : ""}`}
        fullWidth={fullWidth}
        {...restOfTextFieldProps}
        inputRef={inputRef}
        name={name}
        size={size}
        disabled={disabled}
        label={label}
        variant="standard"
      />
      <FormHelperText error={Boolean(error)} margin="dense" variant={variant}>
        {helperText}
      </FormHelperText>
    </StyledFormControl>
  );
});

LabelledTextFieldBootStrap.defaultProps = {
  label: "",
  variant: "standard",
  ref: null,
  type: "text",
  error: false,
  helperText: "",
  className: null,
  inputRef: null,
  required: false,
  fullWidth: true,
  size: "medium",
  disabled: false,
};

export default LabelledTextFieldBootStrap;
