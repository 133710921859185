import React, { useEffect } from "react";

import Dialog from "@mui/material/Dialog";

import { Button, Grid, Typography } from "@mui/material";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Routes from "routes/RoutesConstants";
import { Link } from "react-router-dom";

const addWeeklyTaskSchema = yup.object().shape({
  title: yup.string().required("Description is required"),
});

export default function AddCardDialog(props) {
  const { openDialog, setOpenDialog, is_membership_active, is_card_addedd } =
    props;

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <Dialog
      maxWidth={"xs"}
      open={openDialog}
      onClose={handleClose}
      PaperProps={{
        sx: {
          overflow: "visible",
        },
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className="gap-12"
      >
        <Grid item>
          <Typography align="center" variant="h6">
            Sorry! this is a paid feature, buy subscription in order to use this
            feature
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          alignItems="center"
          spacing={4}
        >
          <Grid item xs={4}>
            <Button fullWidth variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Link
              to={
                !is_card_addedd
                  ? Routes.FREETRAIL
                  : !is_membership_active
                  ? Routes.PROFILE
                  : ""
              }
            >
              <Button fullWidth>Ok</Button>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}
